import { Badge } from 'lib/components/Badge';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { capitalizeWord } from 'lib/utils/strings';
import React from 'react';
import { selectUser } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getBadgeStatusForNewspaperOrderStatus } from 'routes/ads/helpers';
import { logAndCaptureException } from 'utils';
import ToastActions from 'redux/toast';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { ColumnService } from 'lib/services/directory';
import { exists } from 'lib/types';

const editableStatuses = [
  NewspaperOrderStatus.AWAITING_REVIEW,
  NewspaperOrderStatus.IN_REVIEW,
  NewspaperOrderStatus.CONFIRMED
];

type ConfirmationStatusSelectorProps = {
  newspaperOrder: NewspaperOrderModel;
};

export function ConfirmationStatusSelector({
  newspaperOrder
}: ConfirmationStatusSelectorProps) {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const userIsInRelevantNewspaper =
    exists(user) &&
    user.data().activeOrganization?.id === newspaperOrder.data().newspaper.id;

  const onChange = async (
    value: NewspaperOrderStatus
  ): Promise<ResponseOrError<{ updated: boolean }>> => {
    if (!userIsInRelevantNewspaper) {
      return wrapSuccess({ updated: false });
    }

    const { error: updateNewspaperStatusError } =
      await newspaperOrder.updateStatus(value, user.ref);
    if (updateNewspaperStatusError) {
      logAndCaptureException(
        ColumnService.ORDER_MANAGEMENT,
        updateNewspaperStatusError,
        'Failed to update newspaper order status from order details page',
        {
          newspaperOrderId: newspaperOrder.id,
          orderId: newspaperOrder.ref.parent.parent?.id,
          service: ColumnService.ORDER_MANAGEMENT,
          userId: user?.id
        }
      );
      return wrapError(updateNewspaperStatusError);
    }

    return wrapSuccess({ updated: true });
  };

  if (!userIsInRelevantNewspaper) {
    return (
      <Badge
        status={getBadgeStatusForNewspaperOrderStatus(
          newspaperOrder.data().status
        )}
      >
        {capitalizeWord(newspaperOrder.data().status)}
      </Badge>
    );
  }

  return (
    <div className="w-40">
      <ColumnSelect<NewspaperOrderStatus>
        id="order-detail-confirmation-status"
        labelText=""
        size="small"
        options={editableStatuses.map(status => ({
          label: capitalizeWord(status),
          value: status
        }))}
        onChange={async newValue => {
          const { response, error } = await onChange(newValue);
          if (error) {
            dispatch(
              ToastActions.toastError({
                headerText: 'Error',
                bodyText:
                  'There was an error. Please update the confirmation status again.'
              })
            );
          }

          if (response?.updated) {
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: 'Confirmation status updated.'
              })
            );
          }
        }}
        value={newspaperOrder.data().status}
        allowUndefined
        placeholder={capitalizeWord(newspaperOrder.data().status)}
        disabled={!editableStatuses.includes(newspaperOrder.data().status)}
      />
    </div>
  );
}
