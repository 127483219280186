import { getFulfilled, getRejected } from 'lib/helpers';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { getProductDeadlineTimeForPaper } from 'lib/utils/deadlines';
import { Product } from 'lib/enums';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { Moment } from 'moment';
import {
  BadRequestError,
  ColumnError,
  InternalServerError,
  NotFoundError,
  wrapErrorAsColumnError
} from 'lib/errors/ColumnErrors';
import { getFirebaseContext } from 'utils/firebase';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { safeGetModelFromRef } from 'lib/model/getModel';
import { NewspaperOrdersFormData } from '../PlacementFlowStepSelector';

const getAdDeadlineForNewspaperOrder = async (
  product: Product,
  newspaperOrder: Partial<NewspaperOrder>
): Promise<ResponseOrError<Moment, ColumnError>> => {
  const newspaperRef = newspaperOrder.newspaper;
  const { publishingMedium } = newspaperOrder;
  if (!newspaperRef) {
    return wrapError(
      new BadRequestError('Newspaper not set on newspaperOrder')
    );
  }
  if (!publishingMedium) {
    return wrapError(
      new BadRequestError('Publishing medium not set on newspaperOrder')
    );
  }

  if (
    !newspaperOrder.publishingDates ||
    newspaperOrder.publishingDates.length < 1
  ) {
    return wrapError(
      new BadRequestError(
        `No publishing dates found for paper: ${newspaperOrder.newspaper?.id}.`
      )
    );
  }

  const { response: newspaper, error: newspaperError } =
    await safeGetModelFromRef(
      OrganizationModel,
      getFirebaseContext(),
      newspaperRef
    );
  if (newspaperError) {
    return wrapErrorAsColumnError(newspaperError, NotFoundError);
  }
  const [earliestPublishingDate] = newspaperOrder.publishingDates;

  const { response: deadlineTime, error: deadlineTimeError } =
    await getProductDeadlineTimeForPaper(
      newspaper,
      product,
      publishingMedium,
      earliestPublishingDate
    );
  if (deadlineTimeError) {
    return wrapError(deadlineTimeError);
  }
  if (!deadlineTime) {
    return wrapError(new NotFoundError('No deadline found'));
  }
  return wrapSuccess(deadlineTime.deadlineMoment);
};

export const getSoonestAdDeadline = async (
  product: Product,
  newspaperOrdersFormData: NewspaperOrdersFormData
): Promise<ResponseOrError<Moment, ColumnError>> => {
  const deadlinePromises = await Promise.allSettled(
    newspaperOrdersFormData.map(newspaperOrder =>
      getAdDeadlineForNewspaperOrder(product, newspaperOrder)
    )
  );

  const deadlines: Moment[] = [];
  const rejectedPromise = getRejected(deadlinePromises)[0];
  if (rejectedPromise) {
    logAndCaptureCriticalError(
      ColumnService.OBITS,
      rejectedPromise,
      '[OBITS BETA] Error getting ad deadlines for obits publication',
      {
        publishers: newspaperOrdersFormData
          .map(({ newspaper }) => newspaper?.id)
          .join(', ')
      }
    );
    return wrapError(new InternalServerError('Error getting ad deadlines'));
  }

  const deadlineResponses = getFulfilled(deadlinePromises);
  for (const {
    response: deadline,
    error: deadlineError
  } of deadlineResponses) {
    if (deadlineError) {
      logAndCaptureCriticalError(
        ColumnService.OBITS,
        deadlineError,
        '[OBITS BETA] Error getting ad deadlines for obits publication. Please make sure that all publications selected have valid obit deadlines enabled.',
        {
          publishers: newspaperOrdersFormData
            .map(({ newspaper }) => newspaper?.id)
            .join(', ')
        }
      );
      return wrapError(new InternalServerError('Error getting ad deadlines'));
    }
    deadlines.push(deadline);
  }

  if (deadlines.length === 0) {
    return wrapError(new InternalServerError('No valid ad deadlines found'));
  }

  const earliestDeadline = deadlines.reduce((min, current) =>
    current.isBefore(min) ? current : min
  );

  return wrapSuccess(earliestDeadline);
};
