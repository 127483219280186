import {
  DataWithNoticeType,
  maybeGetXMLSyncExportSettings,
  removeUndefinedFields
} from 'lib/helpers';
import Firebase from 'EnoticeFirebase';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import moment from 'moment';
import {
  ESnapshot,
  EOrganization,
  EUser,
  exists,
  ERequestTypes,
  EResponseTypes,
  ENotice,
  ENoticeDraft,
  ERef,
  ESnapshotExists,
  ENoticeFile,
  CustomerOrganization,
  EAddress,
  Customer
} from 'lib/types';
import {
  ImplementationStatus,
  OrganizationType,
  Product,
  State
} from 'lib/enums';
import {
  disableNonPublishingDays,
  publishingDayEnumValuesFromDeadlines,
  getIsAfterPublishingDeadline,
  isAllowedPublicationDay
} from 'lib/utils/deadlines';
import * as EmailValidator from 'email-validator';
import api from 'api';
import {
  getAccountNumberForNotice,
  getAddressFromCustomerInfo,
  getCustomerName,
  getCustomerOrganizationName
} from 'lib/notice/customer';
import { getFirebaseContext } from 'utils/firebase';
import { CustomerFetchMethods, CustomerValidationState } from 'types/customers';
import { DEFAULT_ACCEPT } from 'lib/constants';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import {
  SearchableOrganizationRecord,
  SearchableOrganizationRecordFilter
} from 'lib/types/searchable';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import {
  IntegrationFeature,
  integrationHasAnyFeature,
  integrationHasFeature
} from 'lib/integrations/features';
import { SyncFormat } from 'lib/types/integrations/sync';
import { IntegrationCustomerInfo } from 'lib/types/integrations/request';
import { MadlibDataType, TemplateData } from 'lib/types/madlib';
import lodash, { isEmpty } from 'lodash';
import { reuploadNoticeFileContent } from 'utils/duplicateNotice';
import { getCounties } from 'lib/utils/counties';
import { isFileUploadQuestionInputValue } from 'lib/utils/madlib';
import { userIsSuper } from 'utils/permissions';
import { ColumnService } from 'lib/services/directory';
import { AdjudicationFilters } from '../ConfirmPublisher/ConfirmPublisherStep';

// Maximum number of characters for the `designNotes` property on notices
export const DESIGN_NOTES_MAX_CHARS = 255;

export const SATURDAY_DAY_INDEX = 6;
export const SUNDAY_DAY_INDEX = 0;

/**
 * removeDatesFromArray helper function deletes dates from an array of dates
 * @param pubDates array of dates
 * @param itemsToRemove array of items to remove from pubDates
 * @returns a new array with items removed
 */
export const removeDatesFromArray = (
  pubDates: Date[],
  itemsToRemove: Date[]
): Date[] => pubDates.filter(date => !itemsToRemove.includes(date));

/**
 * removeRowClickForWeekendEdition deletes dates from an array of publication dates and makes sure weekend dates are always deleted together
 * (ex: deleting Saturday deletes Sunday as well)
 *
 * @param publicationDates array containing publication dates
 * @param i row clicked in app, the index of the element in publicationDates array
 * @returns a new array removing weekends from publicationDates
 */
export const removeRowClickForWeekendEdition = (
  publicationDates: Date[],
  i: number
): Date[] => {
  // Remove both weekend days if publication date clicked is a Sunday
  if (moment(publicationDates[i]).day() === SATURDAY_DAY_INDEX) {
    return removeDatesFromArray(publicationDates, [
      publicationDates[i],
      publicationDates[i + 1]
    ]);
    // Remove both weekend days if publication date clicked is a Sunday
  }
  if (moment(publicationDates[i]).day() === SUNDAY_DAY_INDEX) {
    return removeDatesFromArray(publicationDates, [
      publicationDates[i - 1],
      publicationDates[i]
    ]);
    // Remove the weekday
  }
  return removeDatesFromArray(publicationDates, [publicationDates[i]]);
};

/**
 * addPublicationDates adds dates to an array of publication dates ensuring weekends are added together
 * (ex. adding SAturday adds Sunday as well)
 *
 * @param selectedPublicationDate date selected in date picker
 * @param publicationDates array containing publication dates
 * @returns a new array removing weekends from publicationDates
 */
export const addPublicationDates = (
  selectedPublicationDate: Date,
  publicationDates: Date[]
): Date[] => {
  if (moment(selectedPublicationDate).day() === SATURDAY_DAY_INDEX) {
    const sunday = moment(selectedPublicationDate).day(7).toDate();
    return [...publicationDates, selectedPublicationDate, sunday];
  }
  if (moment(selectedPublicationDate).day() === SUNDAY_DAY_INDEX) {
    const saturday = moment(selectedPublicationDate).day(-1).toDate();
    return [...publicationDates, saturday, selectedPublicationDate];
  }
  return [...publicationDates, selectedPublicationDate];
};

/**
 * removeDuplicateDates deletes duplicate dates from an array
 * @param publicationDates an array of dates
 * @returns a new array of unique dates
 */
export const removeDuplicateDates = (publicationDates: Date[]): Date[] => {
  const datesAsStrings: string[] = [];
  const uniqueDates: Date[] = [];
  for (const date of publicationDates) {
    if (!datesAsStrings.includes(date.toString())) {
      datesAsStrings.push(date.toString());
      uniqueDates.push(date);
    }
  }

  return uniqueDates;
};

/**
 * handlePubDateChangeForWeekendEdition when weekend edition enabled:
 * 1. removes the clicked date, the row clicked in app
 * 2. adds a date chosen form the date picker
 * 3. deletes duplicate dates from the array
 *
 * @param selectedPublicationDate date selected in date picker
 * @param publicationDates array containing publication dates
 * @param i row clicked in app, the index of the element in publicationDates array
 * @returns a new array removing publicationDates[i], adding selectedPublicationDate
 */
export const handlePubDateChangeForWeekendEdition = (
  selectedPublicationDate: Date,
  publicationDates: Date[],
  i: number
): Date[] => {
  let newPubDates = removeRowClickForWeekendEdition(publicationDates, i);
  newPubDates = addPublicationDates(selectedPublicationDate, newPubDates);
  newPubDates = removeDuplicateDates(newPubDates);

  return newPubDates;
};

/**
 * Checks if the given date should be disabled in the confirm schedule step
 */
export const shouldDisableDate = ({
  day,
  newspaper,
  user,
  notice,
  noticeType,
  isPublisher
}: {
  day: Date;
  newspaper: ESnapshot<EOrganization> | undefined;
  user: ESnapshot<EUser> | null | undefined;
  notice: DataWithNoticeType;
  noticeType: CustomNoticeFilingType | null | undefined;
  isPublisher: boolean;
}) => {
  // Super users (advertisers or publishers) are not subject to publication
  // date restrictions
  if (exists(user) && userIsSuper(user)) {
    return false;
  }

  if (!exists(newspaper)) {
    return false;
  }

  const { deadlines, deadlineOverrides = {}, iana_timezone } = newspaper.data();
  if (!deadlines || !day || !iana_timezone) {
    return true;
  }

  const isNonPublishingDay = disableNonPublishingDays(
    day,
    publishingDayEnumValuesFromDeadlines(deadlines),
    newspaper.data().deadlineOverrides
  );

  /**
   * NOTE: I'm not sure why we're adding an hour here, and I'm not sure if we're properly
   * accounting for the newspaper vs. user device timezone when setting the hour.
   * Previously we were changing the hour of the day passed in directly, which
   * was causing the date to shift later one hour every time a new date was added to the
   * publication dates in the UI. Leaving this adjustment for the deadline check,
   * but removed the date shifting.
   */
  const testTimestamp = new Date(day).setHours(day.getHours() + 1);
  const testDate = new Date(testTimestamp);
  const isAfterDeadline = getIsAfterPublishingDeadline(
    testDate,
    deadlines,
    deadlineOverrides,
    iana_timezone,
    notice,
    newspaper
  );

  if (!isPublisher) {
    return (
      isAfterDeadline ||
      !isAllowedPublicationDay(day, noticeType) ||
      isNonPublishingDay
    );
  }

  return isNonPublishingDay;
};

type FetchPublisherCustomerResult =
  | {
      success: true;
      method:
        | CustomerFetchMethods.FROM_CUSTOMER_INFO
        | CustomerFetchMethods.FROM_ACCOUNT_NUMBER;
      customer: IntegrationCustomerInfo;
    }
  | {
      success: true;
      method: CustomerFetchMethods.ALREADY_VERIFIED;
    }
  | {
      success: false;
      reason:
        | CustomerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN
        | CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO
        | CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_ID
        | CustomerValidationState.DID_NOT_SEARCH;
    };

export const fetchPublisherCustomer = async ({
  accountNumber,
  verifiedAccountNumber,
  customerInfo,
  newspaperSnap
}: {
  accountNumber: string | null;
  verifiedAccountNumber: string | null;
  customerInfo:
    | ERequestTypes['integrations/customers/:newspaperId/find']
    | null;
  newspaperSnap: ESnapshot<EOrganization> | null;
}): Promise<FetchPublisherCustomerResult> => {
  if (!exists(newspaperSnap) || (!accountNumber && !customerInfo?.email)) {
    return {
      success: false,
      reason: CustomerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN
    };
  }

  const currentAccountNumberIsVerified =
    !!accountNumber && accountNumber === verifiedAccountNumber;
  if (currentAccountNumberIsVerified) {
    return {
      success: true,
      method: CustomerFetchMethods.ALREADY_VERIFIED
    };
  }

  const settings = await maybeGetXMLSyncExportSettings(newspaperSnap);

  const canFindByInfo = integrationHasFeature(
    settings?.format,
    IntegrationFeature.CUSTOMERS_FIND_BY_INFO
  );
  const shouldFindCustomerWithInfo =
    canFindByInfo &&
    !accountNumber &&
    !!customerInfo?.email &&
    !!EmailValidator.validate(customerInfo.email) &&
    !!customerInfo?.firstName &&
    !!customerInfo?.lastName;

  const canFindByAccountNumber = integrationHasFeature(
    settings?.format,
    IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER
  );
  const shouldFindCustomerWithAccountNumber =
    canFindByAccountNumber && !!accountNumber;

  if (shouldFindCustomerWithInfo) {
    try {
      const resp: EResponseTypes['integrations/customers/:newspaperId/find'] =
        await api.post(
          `integrations/customers/${newspaperSnap.id}/find`,
          customerInfo
        );
      if (resp.success) {
        return {
          success: true,
          method: CustomerFetchMethods.FROM_CUSTOMER_INFO,
          customer: resp.customer
        };
      }
      return {
        success: false,
        reason: CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO
      };
    } catch (err) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        err,
        'failed to find publisher customer by customer info',
        {
          ...removeUndefinedFields(customerInfo),
          state: `${customerInfo.state}`,
          newspaperId: newspaperSnap.id
        }
      );
      return {
        success: false,
        reason: CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO
      };
    }
  }

  if (shouldFindCustomerWithAccountNumber) {
    try {
      const resp: EResponseTypes['integrations/customers/:newspaperId/find'] =
        await api.get(
          `integrations/customers/${newspaperSnap.id}/find/${accountNumber}`
        );
      if (resp.success) {
        return {
          success: true,
          method: CustomerFetchMethods.FROM_ACCOUNT_NUMBER,
          customer: resp.customer
        };
      }
      return {
        success: false,
        reason: CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_ID
      };
    } catch (err) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        err,
        'failed to find publisher customer by account number',
        {
          newspaperId: newspaperSnap.id,
          accountNumber
        }
      );
      return {
        success: false,
        reason: CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_ID
      };
    }
  }

  return {
    success: false,
    reason: CustomerValidationState.DID_NOT_SEARCH
  };
};

export const fetchAccountNumber = async ({
  noticeSnap,
  filerRef,
  filedByRef
}: {
  noticeSnap: ESnapshot<ENotice | ENoticeDraft> | null;
  filerRef: ERef<EUser> | null;
  filedByRef: ERef<EOrganization> | null;
}): Promise<
  | {
      success: true;
      accountNumber: string;
    }
  | {
      success: false;
    }
> => {
  if (!exists(noticeSnap)) {
    return {
      success: false
    };
  }

  const filerSnap = await filerRef?.get();
  const filerSnapExists = exists(filerSnap) ? filerSnap : undefined;

  const filedBySnap = await filedByRef?.get();
  const filedBySnapExists = exists(filedBySnap) ? filedBySnap : undefined;

  const fetchResults = await getAccountNumberForNotice(
    getFirebaseContext(),
    noticeSnap,
    filerSnapExists,
    filedBySnapExists
  );

  const fetchedAccountNumber = fetchResults?.id;

  if (fetchedAccountNumber) {
    return {
      success: true,
      accountNumber: fetchedAccountNumber
    };
  }
  return {
    success: false
  };
};

export const getMaskedEmail = (email?: string) => {
  if (!email) return '';

  const emailPartsSplitByAmpersand = email.toLowerCase().split('@');
  const username = emailPartsSplitByAmpersand[0];
  // eslint-disable-next-line no-useless-escape
  const [domain, tld] = emailPartsSplitByAmpersand[1].split(/\.(?=[^\.]+$)/);

  return `${username.length > 1 ? username.substring(0, 2) : username[0]}****@${
    domain[0]
  }***.${tld}`;
};

export const getOriginalFirebaseStoragePath = (noticeFile: ENoticeFile) => {
  const storagePath = noticeFile.originalFirebaseStoragePath;

  // TODO: Confirm if this check covers all scenarios
  if (storagePath && storagePath.includes('_smashed')) {
    return storagePath.split('_smashed')[0];
  }

  return storagePath;
};

export const getUploadedFileURL = (noticeFile: ENoticeFile) => {
  return noticeFile.linkToUploadedFile || '';
};

export const getOriginalFileName = (noticeFile: ENoticeFile) => {
  return noticeFile.originalFileName || null;
};

const LINER_ACCEPT =
  '.pdf,.docx,.doc,.txt,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/plain';

const DISPLAY_ACCEPT =
  '.rtf,.jpg,.jpeg,.png,.pdf,.csv,.xls,.xlsx,application/rtf,text/rtf,image/jpeg,image/png,application/pdf,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export function getAcceptedFiles(
  newspaper: ESnapshot<EOrganization> | undefined
) {
  // default behavior
  if (!exists(newspaper)) return DEFAULT_ACCEPT;

  const { customAccept } = newspaper.data();
  // go to custom values if set
  if (customAccept) return customAccept;

  // restrict behavior for only display ads
  if (newspaper?.data()?.displayOnlyAds) return DISPLAY_ACCEPT;

  // restrict behavior for disable display
  if (newspaper.data().disableDisplay || newspaper.data().allowedNotices)
    return LINER_ACCEPT;

  // fall through to default
  return DEFAULT_ACCEPT;
}

export const isAnonymousFlow = () => !!Firebase.auth().currentUser?.isAnonymous;

/* Finds which papers the user can place in. For this we have the following cases:
 * 1- If the user is a publisher, they are restricted to place in the papers they have access to only.
 * 2- If the placement URL has `restrictedPapers` param, these are the papers, the user can only place in.
 * 3- Otherwise, use can place in any valid paper
 * */
export const getRestrictedPublisherIds = (
  user: ESnapshotExists<EUser> | null,
  isPublisher: boolean,
  availableOrganizations: ESnapshotExists<EOrganization>[]
) => {
  let publisherIds: string[] = [];

  // Publishers are allowed only to place in their papers
  if (isPublisher) {
    publisherIds = availableOrganizations.map(org => org.id);
  }

  // We can restrict placement in specific papers by having paper ids in the URL
  const locationRestrictedPublisherIds =
    getRestrictedPublishersFromLocationParams();

  if (locationRestrictedPublisherIds) {
    publisherIds = locationRestrictedPublisherIds;
  }

  return publisherIds;
};

// Fetches all available papers and states to be used in the confirm newspaper step
export const searchPublisherOrganizations = async ({
  isUserPublisher,
  stateFilter,
  search = '',
  restrictedPublisherIds,
  product = Product.Notice,
  adjudicationFilters
}: {
  isUserPublisher: boolean;
  stateFilter?: number;
  search?: string;
  restrictedPublisherIds?: string[];
  product?: Product;
  adjudicationFilters?: AdjudicationFilters;
}): Promise<{
  publisherOrganizations: SearchableOrganizationRecord[];
  states: number[];
}> => {
  // Construct the Elastic filters
  const filters: SearchableOrganizationRecordFilter[] = [];

  // optional filters for adjudication area
  if (adjudicationFilters) {
    const {
      stateFilter: adjudicationState,
      countyFilter,
      cityFilter
    } = adjudicationFilters;
    if (adjudicationState) {
      filters.push({
        states: [adjudicationState.fipsCode]
      });
    }

    if (countyFilter) {
      filters.push({
        counties: [`${adjudicationState?.fipsCode}${countyFilter.fipsCode}`]
      });
    }

    if (cityFilter) {
      filters.push({
        cities: [`${adjudicationState?.fipsCode}${cityFilter.fipsCode}`]
      });
    }
  }
  if (stateFilter) {
    filters.push({
      state: [stateFilter]
    });
  }

  // ONCALL-3647 Temporarily filtering on frontend to fix bug not returning correct results with ID filter
  if (restrictedPublisherIds && restrictedPublisherIds.length) {
    filters.push({ organizationid: restrictedPublisherIds });
  }

  // Only publishers can place in a disabled paper
  if (!isUserPublisher) {
    // We'll eventually have separate disabled statuses for each product line
    if (product === Product.Notice) {
      filters.push({ disabled: [0] });
    }
  }

  filters.push({ type: [OrganizationType.newspaper.value] });

  if (product === Product.Notice) {
    filters.push({ isvalidpaper: [1] });
  } else if (product === Product.Obituary) {
    filters.push({ isacceptingobituaries: [1] });
    filters.push({ obituaryimplementationstatus: [ImplementationStatus.Live] });
  } else if (product === Product.Classified) {
    filters.push({ isacceptingclassifieds: [1] });
    filters.push({
      classifiedimplementationstatus: [ImplementationStatus.Live]
    });
  }

  const postBody: ERequestTypes['search/organizations'] = {
    search,
    filters
  };

  try {
    const { results, error }: EResponseTypes['search/organizations'] =
      await api.post('search/organizations', postBody);

    const filteredResults =
      restrictedPublisherIds && restrictedPublisherIds.length > 0
        ? results.filter(result => {
            return restrictedPublisherIds.includes(result.id);
          })
        : results;

    if (error) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        new Error('Unable to search organizations'),
        `Placement: Error in searchPublisherOrganizations. Filters: ${JSON.stringify(
          filters
        )}`
      );
    }

    const states = new Set(results.map(paper => paper.state));
    return { states: [...states], publisherOrganizations: filteredResults };
  } catch (e) {
    logAndCaptureException(
      ColumnService.WEB_PLACEMENT,
      e,
      'Placement: Error in searchPublisherOrganizations'
    );
    return { publisherOrganizations: [], states: [] };
  }
};

export const getFirstAndLastNameFromFullName = (
  fullName: string | undefined
): { firstName?: string; lastName?: string } => {
  if (!fullName) {
    return { firstName: undefined, lastName: undefined };
  }

  // we want to remove double spaces
  const trimmedFullName = fullName.replace(/\s+/g, ' ').trim();
  const splitTrimmedFullName = trimmedFullName.split(' ');

  // if the name has only one space, return a firstName and lastName
  if (splitTrimmedFullName.length === 2) {
    return {
      firstName: splitTrimmedFullName[0],
      lastName: splitTrimmedFullName[1]
    };
  }
  // if there are a different number of spaces than in most names, set the firstName to the entire name
  // and the lastName to a blank space.
  return { firstName: trimmedFullName, lastName: undefined };
};

export function getStateFromLocationParams() {
  const state = getLocationParams().get('state');
  if (!state) {
    return;
  }

  const existingState = State.by_label(state);
  if (!existingState) {
    return;
  }

  const selectedState = existingState.value;
  if (!selectedState) {
    return;
  }

  return selectedState;
}

export function getRestrictedPublishersFromLocationParams() {
  const restrictedPapers = getLocationParams().get('restrictedPapers');
  if (!restrictedPapers) {
    return null;
  }

  return restrictedPapers.split(',');
}

/**
 * Determines whether to show account ID management features in the placement flow.
 */
export const shouldShowAccountIdFeatures = (
  syncFormat: SyncFormat | undefined
) => {
  const enableAccountIdInPlacement = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_ACCOUNT_ID_IN_PLACEMENT,
    false
  );
  const newspaperSupportsAccountIdStep = integrationHasAnyFeature(syncFormat, [
    IntegrationFeature.CUSTOMERS_FIND_BY_INFO,
    IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER
  ]);

  return (
    /**
     * See APP-361 for more context
     * We don't show the accountId step in the anonymous flow.
     * The accountID step will get moved to the end of placement in the future -
     * in the anon flow, the user gets signed in halfway through after entering
     * customer information. Afterwards, they would be able to enter an account
     * number in the dedicated step if required by the paper.
     */
    !isAnonymousFlow() &&
    enableAccountIdInPlacement &&
    newspaperSupportsAccountIdStep
  );
};

// Get the madlib file object from the madlibData of notice
export const getMadlibFileFromMadlibData = (
  originalNoticeMadlibQuestions: TemplateData
) => {
  if (!originalNoticeMadlibQuestions) return;

  const oldFileData = lodash.pickBy(
    { ...originalNoticeMadlibQuestions },
    isFileUploadQuestionInputValue
  );

  if (!oldFileData || isEmpty(oldFileData)) return null;
  const oldFileDataObj = Object.values(oldFileData)[0];
  const oldFileDataKey = Object.keys(oldFileData)[0];

  return { key: oldFileDataKey, value: oldFileDataObj };
};

/** Get the updated madlib file uploaded data, determine if current draft id exists in file path,
 * if exists we will not reupload the file content; if not the file content reuploaded
 */
export const getNewMadlibFileData = async (
  originalNoticeMadlibQuestions: MadlibDataType,
  originalNoticeId: string,
  draftNoticeId: string,
  isDuplicated?: boolean
) => {
  if (isEmpty(originalNoticeMadlibQuestions.questionTemplateData)) return;
  const oldFileData = getMadlibFileFromMadlibData(
    originalNoticeMadlibQuestions.questionTemplateData
  );
  if (!oldFileData) return;

  if (oldFileData.value.storagePath.includes(draftNoticeId)) return;
  const uploadLocation = `documentcloud/madlib-notices-uploads/${draftNoticeId}`;

  const duplicatePrefix = isDuplicated ? 'duplicated_' : '';
  const newFileData = await reuploadNoticeFileContent(
    oldFileData.value,
    uploadLocation,
    duplicatePrefix
  );

  if (!newFileData) {
    logAndCaptureMessage('Unable to update madlib notice file content', {
      draftNoticeId,
      originalNoticeId,
      noticeFileName: oldFileData.value.sanitizedFileName
    });
    return;
  }

  const newDownloadUrl: string = await newFileData.uploadRef.getDownloadURL();
  const fileData = {
    sanitizedFileName: newFileData.file.name,
    storagePath: newFileData.uploadRef.fullPath,
    linkToUploadedFile: newDownloadUrl
  };
  const updatedQuestionsTemplate = {
    ...originalNoticeMadlibQuestions.questionTemplateData,
    [oldFileData.key]: fileData
  };
  const newMadlib = removeUndefinedFields({
    ...originalNoticeMadlibQuestions,
    questionTemplateData: updatedQuestionsTemplate
  });
  return newMadlib;
};
type DefaultInvoiceRecipient = {
  name: string;
  email: string;
  mailingAddress: EAddress;
};

export const getDefaultInvoiceRecipient = async (
  filer: ERef<EUser>,
  filedBy: ERef<EOrganization> | null,
  customer: ERef<Customer> | null,
  customerOrganization: ERef<CustomerOrganization> | null
): Promise<DefaultInvoiceRecipient> => {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );

  if (enableNewPlacementFlow) {
    let name = '';

    const filerSnap = await filer.get();
    const filedBySnap = await filedBy?.get();
    const customerSnap = await customer?.get();
    const customerOrgSnap = await customerOrganization?.get();

    /**
     * Default invoice recipient hierarchy:
     * 1. Customer organization
     * 2. Customer
     * 3. FiledBy
     * 4. Filer
     */

    // Prefer customer information over filer information
    if (exists(customerSnap) && exists(filerSnap)) {
      if (exists(customerOrgSnap) && exists(filedBySnap)) {
        // Prefer the actual advertiser organization name over customerOrganization name
        // This is to be in sync with invoice PDFs and payment pages,
        // which show the adv organization name rather than the customerOrg name
        name =
          filedBySnap.data().name ||
          getCustomerOrganizationName(customerOrgSnap, customerSnap);
      } else {
        name =
          customerSnap.data().organizationName ||
          getCustomerName(customerSnap, filerSnap, true);
      }
    } else {
      // If no customer exists, prefer the filedBy org name > filer name
      name =
        filedBySnap?.data()?.name ||
        filerSnap.data()?.organizationName ||
        filerSnap.data()?.name ||
        '';
    }

    return {
      name,
      email: filerSnap.data()?.email || '',
      mailingAddress: getAddressFromCustomerInfo(
        customerOrgSnap,
        customerSnap,
        filerSnap
      )
    };
  }
  return {
    name: '',
    email: '',
    mailingAddress: {
      address_line1: '',
      address_line2: '',
      address_city: '',
      address_state: '',
      address_zip: ''
    }
  };
};

export const publisherHasValidCounty = (
  newspaper: ESnapshotExists<EOrganization>
) => {
  const { state, county } = newspaper.data();
  const validCounties = getCounties(State.by_value(state)?.label);

  return validCounties.includes(county);
};

export const removeSubdomainFromHostIfExists = (host: string) => {
  const prefix = host.split('.')[0];
  if (!['column', 'demo', 'staging', 'localhost:3000'].includes(prefix)) {
    return host.split(`${prefix}.`)[1];
  }
  return ''; // no subdomain exists
};
