import React, { useContext } from 'react';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { Alert } from 'lib/components/Alert';
import { capitalize } from 'lodash';
import { FilingTypeVisibility, Product } from 'lib/enums';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { Badge } from 'lib/components/Badge';
import SelectSchedule from './SelectSchedule';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { NewspaperOrderWithPublishingSettings } from './validation';
import { getNewspaperLogo } from '../../helpers/newspapers';

const isValidFilingType = (
  filingType: FilingTypeModel,
  isPublisher: boolean
): boolean => {
  // Filing type must have a rate
  if (!filingType.modelData.rate) {
    return false;
  }

  // Filing type must not be disabled
  if (filingType.modelData.visibility === FilingTypeVisibility.disabled.value) {
    return false;
  }

  if (
    !isPublisher &&
    filingType.modelData.visibility ===
      FilingTypeVisibility.publisher_only.value
  ) {
    return false;
  }

  return true;
};

export const getValidFilingTypes = (
  filingTypes: FilingTypeModel[] | undefined,
  isPublisher: boolean
): FilingTypeModel[] | undefined =>
  filingTypes?.filter(filingType => isValidFilingType(filingType, isPublisher));

type PerPublisherSettingsProps = {
  product: Product;
  newspaperOrderWithPublishingSettings: NewspaperOrderWithPublishingSettings;
  onNewspaperOrderChange: (newspaperOrder: Partial<NewspaperOrder>) => void;
  disableEdits?: boolean;
  disableChangeNumberOfPublicationDates?: boolean;
};

function PerPublisherSettings({
  product,
  newspaperOrderWithPublishingSettings,
  onNewspaperOrderChange,
  disableEdits,
  disableChangeNumberOfPublicationDates
}: PerPublisherSettingsProps) {
  const { newspaperOrder, publishingSettings } =
    newspaperOrderWithPublishingSettings;
  const { publishersAvailableForPlacement } = useContext(NewspapersContext);
  const newspaperSnap = publishersAvailableForPlacement.find(
    snap => snap.id === newspaperOrder.newspaper?.id
  );

  const { publishingSetting } = publishingSettings;

  if (!newspaperSnap) {
    return (
      <Alert
        id="missing-newspaper"
        title="Error"
        description="Missing newspaper"
      />
    );
  }
  if (!publishingSetting) {
    return (
      <Alert
        id="missing-publishing-setting"
        title="Error"
        description="Could not retrieve publishing setting"
      />
    );
  }

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex gap-4 items-center">
        <img
          className="h-12 max-w-48 object-contain rounded-lg"
          src={getNewspaperLogo(newspaperSnap)}
        />
        <h3 className="text-sm font-medium text-column-gray-500">
          {newspaperSnap.data().name}
          <span className="ml-3">
            <Badge>
              {capitalize(
                newspaperOrder.publishingMedium || PublishingMedium.Print
              )}
            </Badge>
          </span>
        </h3>
      </div>

      <SelectSchedule
        newspaperOrder={newspaperOrder}
        newspaper={newspaperSnap}
        onNewspaperOrderChange={onNewspaperOrderChange}
        publishingSetting={publishingSetting}
        product={product}
        disableEdits={disableEdits}
        disableChangeNumberOfPublicationDates={
          disableChangeNumberOfPublicationDates
        }
      />
    </div>
  );
}

export default PerPublisherSettings;
