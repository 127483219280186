import { Ad } from 'lib/types/ad';
import { Product } from 'lib/enums';
import { MadlibDataType } from 'lib/types/madlib';
import MadlibEditor from 'routes/madlib/components/MadlibEditor';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';
import { Layout } from 'lib/types/layout';
import MinimalMce from '../MinimalMce';
import { AdEditorData } from '../FormattedEditorWrapper';
import CopilotEditor from '../CopilotEditor';

const contentPlaceholderByProduct: Record<Product, string> = {
  [Product.Classified]: 'Ad content',
  [Product.Notice]: 'Notice content',
  [Product.Obituary]: 'Write about your loved one...'
};

export type MadlibEditorProps = {
  madlibData: MadlibDataType;
  madlibTemplate: string | undefined;
  setValidationResults: (results: Record<string, boolean>) => void;
  setRenderedHtml: (html: string) => void;
  setMadlibData: (data: MadlibDataType) => void;
};

type ContentEditorProps<T extends Ad> = {
  adData: Partial<T>;
  onChange: (data: AdEditorData) => void;
  disableEditing: boolean;
  product: Product;
  madlibProps: MadlibEditorProps;
  forceFreeEdit: boolean;
  maxWords?: number | null;
  layout: Layout;
};

function ContentEditor<T extends Ad>({
  adData,
  onChange,
  disableEditing,
  product,
  madlibProps,
  forceFreeEdit,
  maxWords,
  layout
}: ContentEditorProps<T>) {
  const {
    madlibData,
    madlibTemplate,
    setValidationResults,
    setRenderedHtml,
    setMadlibData
  } = madlibProps;

  const showMadlibEditor = !!madlibTemplate;

  const ContentEditorComponent = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_COPILOT_EDITOR
  )
    ? CopilotEditor
    : MinimalMce;

  if (showMadlibEditor && !forceFreeEdit) {
    return (
      <MadlibEditor
        rawTemplate={madlibTemplate}
        setRenderedHtml={setRenderedHtml}
        onTemplateDataChange={setMadlibData}
        madlibData={madlibData}
        setValidationResults={setValidationResults}
        /**
         * We remove the `madlibData` and `content` fields from the handlebar data because we refresh the editor anytime the handlebar data changes.
         * Leaving these fields defined would cause the editor to refresh any time the user typed in the editor.
         * */
        handlebarData={{
          ...adData,
          madlibData: undefined,
          content: undefined
        }}
        ignoreStyling
      />
    );
  }

  return (
    <ContentEditorComponent
      toolbarItems={[['undo', 'redo', 'bold', 'italic']]}
      onChange={content => onChange({ content })}
      inline
      minHeight={150}
      value={adData?.content || ''}
      id="ad-content-editor"
      disabled={disableEditing}
      placeholder={
        layout.contentPlaceholder || contentPlaceholderByProduct[product]
      }
      required
      maxWords={maxWords}
      adData={adData}
    />
  );
}

export default ContentEditor;
