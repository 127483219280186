import api from 'api';
import { EResponseTypes, ERequestTypes } from 'lib/types';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';

export const requestNewOrderInvoice = async (
  orderId: string,
  version: number
): Promise<ResponseOrError<string>> => {
  const {
    response: invoiceId,
    error: createError
  }: EResponseTypes['orders/create-invoice'] = await api.safePost(
    'orders/create-invoice',
    {
      orderId,
      version
    }
  );

  if (createError !== null) {
    return wrapError(new Error(createError));
  }

  return wrapSuccess(invoiceId);
};

export const finalizeOrderInvoice = async (
  invoiceId: string
): Promise<ResponseOrError<void>> => {
  const { error: finalizeError } = await api.safePost(
    'orders/finalize-invoice',
    {
      invoiceId
    }
  );

  if (finalizeError !== null) {
    return wrapError(new Error(finalizeError));
  }

  return wrapSuccess(undefined);
};

export const markInvoiceAsPaid = async (
  reqData: ERequestTypes['payments/mark-invoice-paid']
): Promise<ResponseOrError<void>> => {
  const { error: failedToMarkAsPaid } =
    await api.safePost<'payments/mark-invoice-paid'>(
      'payments/mark-invoice-paid',
      reqData
    );

  if (failedToMarkAsPaid) {
    return wrapError(new Error(failedToMarkAsPaid));
  }

  return wrapSuccess(undefined);
};
