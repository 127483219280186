import moment from 'moment';
import { RateType, NoticeType, Day, LineItemType, Product } from '../enums';
import {
  ESnapshotExists,
  ENotice,
  ERef,
  ERate,
  EOrganization,
  ESnapshot,
  EInvoice,
  MailDelivery,
  FirebaseTimestamp,
  EFirebaseContext,
  exists,
  EDisplayParams,
  DiscountConfig
} from '../types';
import {
  AffidavitReconciliationSettings,
  DistributeSettings
} from '../types/organization';
import { InvoiceTrackedFeeSplitResult, LineItem } from '../types/invoices';
import {
  firestoreTimestampOrDateToDate,
  removeUndefinedFields
} from '../helpers';
import {
  COLUMN_REP_FEE,
  ENOTICE_CONVENIENCE_FEE,
  ENOTICE_CONVENIENCE_FEE_GOVT
} from '../constants';
import {
  getAffidavitFeeInCentsAndFeeSplitFromSettingsAndMail,
  getAffidavitFeeInCentsFromSettingsAndMail,
  getAffidavitPricingData,
  getShouldApplyColumnManagedAffidavitFees
} from './affidavits';
import { getErrorReporter } from '../utils/errors';
import {
  getAffidavitAdditionalFeeLineItemsFromRate,
  getNonAffidavitAdditionalFeeLineItemsFromRate,
  getLastNonFeeAndNonDiscountLineItemIndex
} from './additionalFees';
import { getOrThrow } from '../utils/refs';
import { DisplayParams } from '../types/notice';
import {
  PercentAdditionalFee,
  isFlatAdditionalFee,
  isPercentAdditionalFee,
  AdditionalFee,
  AdRate,
  isNoticeRate,
  isOrderRate
} from '../types/rates';
import {
  getActiveDiscountConfigForNotice,
  getUpdatedDiscountLineItemsForNotice
} from '../notice/discounts';
import { dbToUICurrencyString } from './ui';
import {
  getColumnCentimeters,
  getColumnInches,
  getFolios,
  roundUp,
  valueIsNumber
} from './math';
import { oklahoma } from './rateTypes/oklahoma';
import { getInheritedProperty } from '../utils/inheritance';
import {
  NOTICE_ASYNC_DESIGN_FINALIZED,
  NoticeAsyncDesignFinalizedEvent
} from '../types/events';
import { FeeSplit, isFeeSplitTypeFullWaiver } from '../types/feeSplit';
import { fuzzyStringContains } from '../utils/strings';
import findDayRate from './findDayRate';
import calculateSingleRunPrice from './calculateSingleRunPrice';
import getApplicableRate from './getApplicableRate';
import { getDistributedLineItems } from './distributeFee';
import { NewspaperOrder } from '../types/newspaperOrder';
import { Ad } from '../types/ad';

// A lot of other files import 'roundUp' and other helpers from here
export * from './math';

export const DEFAULT_COLUMN_FEE_PCT = 10;

export type ConvenienceFee = {
  convenienceFeePct: number; // a percentage
  convenienceFeeCap: number | undefined; // an amount in cents
};

export type AffidavitPricingData = {
  mail: MailDelivery[] | undefined;
  settings: AffidavitReconciliationSettings | undefined;
  requiresAffidavit: boolean;
};

export const COLUMN_NAME = 'Column, PBC';

/**
 * Calculate the convenience fee in cents.
 */
export const calculateConvenienceFeeAndFeeSplit = (
  subtotal: number,
  fee: ConvenienceFee,
  feeSplit: FeeSplit | undefined
): {
  convenienceFeeInCents: number;
  invoiceTrackedConvenienceFeeSplitResult:
    | InvoiceTrackedFeeSplitResult
    | undefined;
} => {
  const { convenienceFeePct, convenienceFeeCap } = fee;
  const convenienceFeeAmountInCentsNoSplit = Math.round(
    (subtotal * convenienceFeePct) / 100
  );

  let convenienceFeeAmountInCents = convenienceFeeAmountInCentsNoSplit;

  const splitType = feeSplit?.type;

  const feeSplitTypeIsFullWaiver = isFeeSplitTypeFullWaiver(feeSplit);

  if (feeSplitTypeIsFullWaiver) {
    convenienceFeeAmountInCents = 0;
  }

  const splitAmount = feeSplit?.amount ?? 0;

  if (splitType === 'percent') {
    const splitCap = feeSplit?.cap;
    const initialSplitAmtInCents = Math.round(
      (splitAmount / 100) * convenienceFeeAmountInCents
    );
    const splitAmtInCents = valueIsNumber(splitCap)
      ? Math.min(splitCap, initialSplitAmtInCents)
      : initialSplitAmtInCents;
    convenienceFeeAmountInCents = Math.max(
      convenienceFeeAmountInCents - splitAmtInCents,
      0
    );
  }

  if (splitType === 'flat') {
    convenienceFeeAmountInCents = Math.max(
      convenienceFeeAmountInCents - splitAmount,
      0
    );
  }

  const cappedConvenienceFeeAmountInCents = convenienceFeeCap
    ? Math.min(convenienceFeeAmountInCents, convenienceFeeCap)
    : convenienceFeeAmountInCents;

  const feeSplitAmountInCents =
    convenienceFeeAmountInCentsNoSplit - cappedConvenienceFeeAmountInCents;

  const invoiceTrackedConvenienceFeeSplitResult = !feeSplit
    ? undefined
    : {
        feeSplit,
        amountInCents: feeSplitTypeIsFullWaiver ? 0 : feeSplitAmountInCents,
        ...(feeSplitTypeIsFullWaiver && { amountWaived: feeSplitAmountInCents })
      };

  return {
    convenienceFeeInCents: cappedConvenienceFeeAmountInCents,
    invoiceTrackedConvenienceFeeSplitResult
  };
};

export const calculateConvenienceFee = (
  subtotal: number,
  fee: ConvenienceFee,
  feeSplit: FeeSplit | undefined
) => {
  const { convenienceFeeInCents } = calculateConvenienceFeeAndFeeSplit(
    subtotal,
    fee,
    feeSplit
  );
  return convenienceFeeInCents;
};

/**
 * Determines how much of the affidavit fee to charge to a particular notice based
 * on the subtotal. Right now we only charge the affidavit fee for notices that cost >$0
 * and have a convenience fee > 0%.
 *
 * TODO: this function should be deprecated once we have a better way of determining the
 * cost of additional fees for notices that cost $0.
 */
const getFinalAffidavitFeeInCents = (options: {
  subtotalInCents: number;
  defaultAffidavitFeeInCents: number;
  convenienceFee: ConvenienceFee;
  affidavitSettings: AffidavitReconciliationSettings | undefined;
  requiresAffidavit: boolean;
  publicationDates: ENotice['publicationDates'] | undefined;
}): number => {
  const shouldApplyColumnManagedAffidavitFees =
    getShouldApplyColumnManagedAffidavitFees(
      options.affidavitSettings,
      options.publicationDates,
      options.requiresAffidavit
    );
  if (!shouldApplyColumnManagedAffidavitFees) {
    return 0;
  }

  if (options.subtotalInCents === 0) {
    return 0;
  }

  if (options.convenienceFee.convenienceFeePct <= 0) {
    return 0;
  }

  return options.defaultAffidavitFeeInCents;
};

const relevantDisplayParameterFromRate = (
  rateRecord: ERate,
  displayParameters: EDisplayParams,
  columns: number
) => {
  if (rateRecord.rateType === RateType.flat.value) return null;
  if (rateRecord.rateType === RateType.per_run.value) return null;
  if (rateRecord.rateType === RateType.word_count.value)
    return displayParameters.words;
  if (rateRecord.rateType === RateType.inch.value)
    return roundUp(
      displayParameters.height * displayParameters.width,
      rateRecord.roundOff
    ).toFixed(2);
  if (rateRecord.rateType === RateType.column_inch.value)
    return getColumnInches(
      displayParameters.height,
      columns,
      rateRecord.roundOff
    ).toFixed(2);
  if (rateRecord.rateType === RateType.line.value)
    return displayParameters.lines;

  if (rateRecord.rateType === RateType.folio.value)
    return getFolios(displayParameters.words!);

  if (rateRecord.rateType === RateType.nebraska.value)
    return displayParameters.lines;
  if (rateRecord.rateType === RateType.oklahoma.value)
    return displayParameters.lines;
  if (rateRecord.rateType === RateType.iowa_form.value)
    return displayParameters.lines;
  if (rateRecord.rateType === RateType.battle_born.value)
    return displayParameters.lines;
  if (rateRecord.rateType === RateType.berthoud_government.value)
    return `${displayParameters.lines} lines, ${getColumnInches(
      displayParameters.height,
      columns,
      rateRecord.roundOff
    ).toFixed(2)} total column inches`;
  if (rateRecord.rateType === RateType.enterprise.value)
    // This is the only rate type where we round after calculating column inches
    return `${roundUp(
      getColumnInches(displayParameters.height, columns, null),
      rateRecord.roundOff
    ).toFixed(2)} total column inches`;
  if (rateRecord.rateType === RateType.single_column_centimetre.value) {
    return `${getColumnCentimeters(
      displayParameters.height,
      columns,
      rateRecord.roundOff
    ).toFixed(2)} total scm`;
  }
  throw new Error(`Unknown rate type ${rateRecord.rateType}`);
};

export const floatToDBPercent = (pct: number | string): number => {
  if (pct === null || pct === undefined) {
    return 0;
  }

  const parsedPct = typeof pct === 'string' ? parseFloat(pct) : pct;
  const floatStr = parsedPct.toFixed(2);
  return parseFloat(floatStr);
};

export const calculateSubtotalFromLineItems = (lineItems: LineItem[]) => {
  const subtotal = lineItems.reduce(
    (acc, lineItem) => acc + lineItem.amount,
    0
  );
  return subtotal;
};

const calculateTaxFromLineItems = (lineItems: LineItem[], taxPct: number) => {
  const taxAmt = lineItems.reduce(
    (acc, lineItem) => acc + Math.round((lineItem.amount * taxPct) / 100),
    0
  );
  return taxAmt;
};

/**
 * Check if two prices are functionally the same (not off by more than 0.5 cents)
 */
export const pricesAreTheSame = (
  amountAInCents: number,
  amountBInCents: number
) => {
  return Math.abs(amountAInCents - amountBInCents) < 0.5;
};

export const getInvoiceAmountsBreakdown = (
  invoiceSnap: ESnapshotExists<EInvoice>
): {
  /**
   * The sum of all invoice line-item amounts, including any additional fees collected by the publisher (excluding sales tax!)
   */
  subtotalInCents: number;

  /**
   * The monetary amount calculated by (1) multiplying each taxable line item by the tax percentage expressed as a decimal,
   * (2) rounding the product of each such calculation to the nearest cent, then (3) summing the rounded results (see calculateTaxFromLineItems)
   */
  taxesInCents: number;

  /**
   * The sum of the subtotal and the tax amount
   */
  publisherAmountInCents: number;

  /**
   * The monetary amount, rounded to the nearest cent, calculated as the subtotal multiplied by the convenience fee percentage,
   * expressed as a decimal, and capped at the specified fee cap, if applicable
   */
  convenienceFeeInCents: number;

  /**
   * A fixed (i.e. independent of other invoice properties) monetary amount, defined in cents on the relevant affidavit reconciliation settings
   */
  automatedAffidavitFeeInCents: number;

  /**
   * The sum of the convenience fee and the automated affidavit fee
   */
  columnAmountInCents: number;

  /**
   * The sum of the publisher amount and the Column amount
   */
  totalInCents: number;
} => {
  const {
    pricing,
    inAppTaxPct,
    inAppLineItems,
    convenienceFeePct,
    convenienceFeeCap
  } = invoiceSnap.data();

  const {
    publisherAmountInCents,
    totalInCents,
    affidavitFeeInCents: possibleAffidavitFee,
    publisherFeeSplits
  } = pricing;
  const affidavitFeeInCents = possibleAffidavitFee || 0;
  // TODO:
  // - Should this handle distributed fees differently?
  const subtotalInCents = calculateSubtotalFromLineItems(inAppLineItems);
  const convenienceFeeInCents = calculateConvenienceFee(
    subtotalInCents,
    {
      convenienceFeePct,
      convenienceFeeCap
    },
    publisherFeeSplits?.convenienceFeeSplitResult?.feeSplit
  );

  const calculatedColumnAmountInCents =
    affidavitFeeInCents + convenienceFeeInCents;
  const taxesInCents = calculateTaxFromLineItems(inAppLineItems, inAppTaxPct);
  const calculatedPublisherAmount = subtotalInCents + taxesInCents;

  if (
    totalInCents &&
    !pricesAreTheSame(
      calculatedPublisherAmount + calculatedColumnAmountInCents,
      totalInCents
    )
  ) {
    const delta =
      totalInCents -
      (calculatedPublisherAmount + calculatedColumnAmountInCents);
    const tagsForSentry = {
      invoiceID: invoiceSnap.id,
      noticeID: invoiceSnap.data().noticeId ?? 'unknown',
      totalInCents: `${totalInCents}`,
      subtotalInCents: `${subtotalInCents}`,
      taxesInCents: `${taxesInCents}`,
      feesInCents: `${calculatedColumnAmountInCents}`,
      delta: `${delta}`
    };

    if (!pricesAreTheSame(publisherAmountInCents, calculatedPublisherAmount)) {
      getErrorReporter().logAndCaptureWarning(
        '[getInvoiceAmountsBreakdown] Publisher amount on invoice is not the same as calculated publisher amount',
        tagsForSentry
      );
    }

    if (
      pricesAreTheSame(totalInCents, subtotalInCents) ||
      pricesAreTheSame(totalInCents, calculatedPublisherAmount)
    ) {
      getErrorReporter().logAndCaptureWarning(
        '[getInvoiceAmountsBreakdown] Invoice total and subtotal are equal, even though the fee is non-zero',
        tagsForSentry
      );
    } else {
      getErrorReporter().logAndCaptureWarning(
        `[getInvoiceAmountsBreakdown] Invoice subtotal plus calculated fees does not match invoice total (${
          Math.abs(delta) > 0.5
            ? 'Delta greater than $0.50'
            : 'Delta no more than $0.50'
        })`,
        tagsForSentry
      );
    }
  }

  const columnAmountInCents = totalInCents
    ? totalInCents - calculatedPublisherAmount
    : calculatedColumnAmountInCents;

  return {
    subtotalInCents,
    taxesInCents,
    publisherAmountInCents,
    convenienceFeeInCents,
    automatedAffidavitFeeInCents: affidavitFeeInCents,
    columnAmountInCents,
    totalInCents:
      totalInCents || calculatedPublisherAmount + columnAmountInCents
  };
};

/**
 * Get the rate from a notice, falling back to the newspaper's
 * default liner or default display rate as appropriate.
 */
const getNoticeRateRef = (
  notice: ENotice,
  newspaper: EOrganization
): ERef<ERate> => {
  const { rate, noticeType } = notice;
  if (rate) {
    return rate;
  }

  const defaultRate =
    noticeType === NoticeType.display_ad.value
      ? newspaper.defaultDisplayRate
      : newspaper.defaultLinerRate;

  if (!defaultRate) {
    throw new Error(`Newspaper ${newspaper.name} missing default rates`);
  }

  return defaultRate;
};

const ordinalSuffix = (num: number) => {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

const getRelevantRateDescriptionNoBold = (
  rateRecord: ERate,
  numPublicationDates: number,
  currency: string
) => {
  const rateType = RateType.by_value(rateRecord.rateType);

  if (rateType?.long_description) {
    return rateType.long_description;
  }

  if (rateRecord.runBased) {
    const publishNum = numPublicationDates;
    let ratesDesc = '';
    for (let i = 1; i <= publishNum; i++) {
      ratesDesc += `${currency}${dbToUICurrencyString(
        getApplicableRate(numPublicationDates, rateRecord, i)
      )} / ${
        RateType.by_value(rateRecord.rateType)?.singular
      } for ${i}${ordinalSuffix(i)} run${i !== publishNum ? ', ' : ''}`;
    }
    return ratesDesc;
  }

  return `${currency}${dbToUICurrencyString(
    getApplicableRate(numPublicationDates, rateRecord, 1)
  )} / ${rateType?.singular}`;
};

/**
 * Give a human readable rate description.
 *
 * Ex:
 * '$1.00 / Column Inch'
 */
export const getRelevantRateDescription = (
  rateRecord: ERate,
  numPublicationDates: number,
  currency: string
): string => {
  if (!rateRecord) {
    return '';
  }

  let boldDescription = '';

  if (rateRecord.bold_words) {
    boldDescription = `. Bolded Styling -
    ${currency}${dbToUICurrencyString(rateRecord.bold_words)} / Words.`;
  } else if (rateRecord.line_with_bold_words) {
    boldDescription = `. Bolded Styling -
    ${currency}${dbToUICurrencyString(
      rateRecord.line_with_bold_words
    )} / Line with bolding.`;
  }

  return `${getRelevantRateDescriptionNoBold(
    rateRecord,
    numPublicationDates,
    currency
  )}${boldDescription}`;
};

export const getRelevantRateString = (
  rateRecord: ERate,
  displayParameters: EDisplayParams,
  columns: number
) => {
  const ratePlural = RateType.by_value(rateRecord.rateType)?.plural;
  const relevantParam = relevantDisplayParameterFromRate(
    rateRecord,
    displayParameters,
    columns
  );

  if (rateRecord.rateType === RateType.oklahoma.value)
    return `Tabular Lines: ${oklahoma.getTabularLines(
      displayParameters
    )}, Body Words: ${oklahoma.getBodyWords(displayParameters)}`;

  if (rateRecord.rateType === RateType.single_column_centimetre.value)
    return `Single column centimetres: ${relevantParam}`;

  if (rateRecord.rateType === RateType.per_run.value) return '';
  return rateRecord.rateType === RateType.column_inch.value
    ? `Height: ${roundUp(displayParameters.height, rateRecord.roundOff).toFixed(
        2
      )} / Columns: ${columns}`
    : `${ratePlural}: ${relevantParam ? `${relevantParam}` : 'n/a'}`;
};

const DEFAULT_DISPLAY_PARAMS: EDisplayParams = {
  imgs: [],
  columns: 0,
  words: 0,
  lines: 0,
  area: 0,
  height: 0,
  width: 0,
  headerLines: 0,
  headerWords: 0,
  nonTableBoldedLines: 0,
  boldWords: 0,
  justifications: {
    RIGHT_ALIGN: {
      lines: 0,
      words: 0
    },
    LEFT_ALIGN: {
      lines: 0,
      words: 0
    },
    CENTER_ALIGN: {
      lines: 0,
      words: 0
    },
    LEFT_JUSTIFIED: {
      lines: 0,
      words: 0
    }
  },
  areAnyTablesTooWide: false,
  isTableOverflow: false,
  maxHeightExceeded: false
};

export type DBPricingObj = {
  lineItems: LineItem[];
  subtotal: number;
  taxPct: number;
  taxAmt: number;
  convenienceFeePct: number | null;
  convenienceFee: number | null;
  // convenienceFeeSplit is copied down from the rate at time of notice submission
  convenienceFeeSplit?: FeeSplit;
  affidavitFeeInCents?: number;
  total: number;
  // we are setting the convenience fee to null in distributeDbPrice which is leading to incorrect payouts,
  // If we are distributing the fee, we can determine the original fee by accessing the property distributedFee
  distributed?: boolean;
  distributedFee?: number;
};

export type DistributedDBPricingObj = DBPricingObj & {
  convenienceFeePct: null;
  convenienceFee: null;
  distributed: true;
  distributedFee: number;
};

/**
 * Additional fee line items are publisher fees on the organiztion object
 * Fee line items will NOT start with a date string in the description (ex. description: Affidavit Fee)
 * Non-fee line item will start with a date string (ex. 07/01/2022: Custom Notice)
 *
 * @param lineItem
 * @returns boolean
 */
export const isAdditionalFeeLineItem = (lineItem: LineItem): boolean => {
  // Prefer the type field when it exists
  if (typeof lineItem.type === 'number') {
    return lineItem.type === LineItemType.fee.value;
  }

  // no description means not custom
  if (!lineItem.description) return false;

  // return false on line items of the format mm/dd/yyyy:
  if (lineItem.description.match(/^\d{2}\/\d{2}\/\d{4}/)) return false;

  return true;
};

export const isPublicationLineItem = (lineItem: LineItem): boolean => {
  if (typeof lineItem.type === 'number') {
    return lineItem.type === LineItemType.publication.value;
  }

  return false;
};

/**
 * Get or infer the LineItemType from a LineItem
 */
export const getLineItemType = (item: LineItem): number => {
  const { type } = item;
  if (typeof type === 'number') {
    return type;
  }

  if (item.singleNoticeId) {
    return LineItemType.bulk_invoice.value;
  }

  return isAdditionalFeeLineItem(item)
    ? LineItemType.fee.value
    : LineItemType.publication.value;
};

/**
 * Retrieves the distribute Fee settings either from the rate or the newspaper respectively
 */
export const getDistributeFeeSettings = (
  newspaper: ESnapshot<EOrganization>,
  rate: ESnapshot<ERate> | undefined
): DistributeSettings | null | undefined => {
  return (
    rate?.data()?.distributeEnoticeFee || newspaper.data()?.distributeEnoticeFee
  );
};

/**
 * Transform the line items of a notice's 'pricing' field into invoice line items
 * which can be edited by the user in the InvoiceForm UI.
 */
export const getInvoiceLineItems = (
  pricing: DBPricingObj,
  activeOrganization: ESnapshotExists<EOrganization>,
  rateSnap: ESnapshotExists<ERate>
): LineItem[] => {
  // Calculate the total additional fees by parsing line items
  let additionalFeeTotal = 0;
  pricing.lineItems.forEach(item => {
    if (!isPublicationLineItem(item)) {
      additionalFeeTotal += item.amount;
    }
  });
  additionalFeeTotal = Math.round(additionalFeeTotal);

  return pricing.lineItems.map((item, i) => {
    const { description, unitPricing } = item;
    const amount = Math.round(item.amount);
    const date = firestoreTimestampOrDateToDate(item.date);
    const type = getLineItemType(item);

    if (
      getDistributeFeeSettings(activeOrganization, rateSnap)?.finalLineItem &&
      rateSnap.data().finalLineItemPricing &&
      rateSnap.data().rateType === RateType.flat.value
    ) {
      return {
        amount:
          i === 0
            ? pricing.subtotal - additionalFeeTotal
            : !isPublicationLineItem(item)
            ? amount
            : 0,
        unitPricing,
        date,
        description,
        type
      };
    }

    if (rateSnap.data().rateType === RateType.flat.value) {
      return {
        amount,
        unitPricing,
        date,
        description,
        type
      };
    }

    return {
      amount,
      unitPricing,
      date,
      description,
      type
    };
  });
};

/**
 * Get all the line items from an invoice representing single publications.
 */
export const getPublicationLineItems = (invoice: ESnapshotExists<EInvoice>) => {
  const { inAppLineItems } = invoice.data();
  return inAppLineItems
    .filter(item => {
      if (item.refund || item.singleNoticeId) {
        return false;
      }

      if (isAdditionalFeeLineItem(item)) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      const aDate = firestoreTimestampOrDateToDate(a.date);
      const bDate = firestoreTimestampOrDateToDate(b.date);
      return aDate.getTime() - bDate.getTime();
    });
};

export const getFeeLineItems = (invoice: ESnapshotExists<EInvoice>) => {
  const { inAppLineItems } = invoice.data();
  return inAppLineItems.filter(item => isAdditionalFeeLineItem(item));
};

/**
 * Find the line item on an invoice that corresponds to publication
 * for a given date.
 */
export const getPublicationLineItemForDate = (
  invoice: ESnapshotExists<EInvoice>,
  publicationDate: Date
) => {
  const publicationLineItems = getPublicationLineItems(invoice);
  const sameDayLineItems = publicationLineItems.filter(item => {
    const itemDate = firestoreTimestampOrDateToDate(item.date);
    return moment(itemDate).isSame(moment(publicationDate), 'day');
  });

  if (sameDayLineItems.length === 0) {
    getErrorReporter().logAndCaptureWarning(
      'Invoice has no line items for day',
      {
        invoiceId: invoice.id,
        publicationDate: publicationDate.toISOString()
      }
    );
    return;
  }

  if (sameDayLineItems.length >= 2) {
    getErrorReporter().logAndCaptureWarning(
      'Invoice has multiple line items for one day',
      {
        invoiceId: invoice.id,
        publicationDate: publicationDate.toISOString()
      }
    );

    return undefined;
  }

  return sameDayLineItems[0];
};

/**
 * Find the the notice publication date corresponding to a given line item on an invoice.
 */
export const getPubDateForPublicationLineItem = (
  lineItem: LineItem,
  publicationDates: FirebaseTimestamp[]
) => {
  const samePublicationDates = publicationDates.filter(pubDate => {
    const lineItemDate = firestoreTimestampOrDateToDate(lineItem.date);
    const pubDateDate = firestoreTimestampOrDateToDate(pubDate);
    return moment(lineItemDate).isSame(moment(pubDateDate), 'day');
  });

  return samePublicationDates[0];
};

/**
 * Find notice pub dates that aren't on a given invoice
 */
export const getNoticePubDatesNotOnInvoice = (
  invoice: ESnapshotExists<EInvoice>,
  publicationDates: FirebaseTimestamp[]
): FirebaseTimestamp[] => {
  if (!publicationDates || publicationDates.length === 0) {
    return [];
  }

  return publicationDates.filter(pubDate => {
    const pubDateAsDate = firestoreTimestampOrDateToDate(pubDate);
    return !getPublicationLineItemForDate(invoice, pubDateAsDate);
  });
};

/**
 * Find the difference in run dates
 */
export const getPubDateAndPublicationLineItemsDifference = (
  invoice: ESnapshotExists<EInvoice>,
  publicationDates: FirebaseTimestamp[]
) => {
  const pubDateDiffs: FirebaseTimestamp[] = getNoticePubDatesNotOnInvoice(
    invoice,
    publicationDates
  );

  const publicationLineItemDiffs: LineItem[] = getPublicationLineItems(
    invoice
  ).filter(lineItem => {
    return !getPubDateForPublicationLineItem(lineItem, publicationDates);
  });

  return { pubDateDiffs, publicationLineItemDiffs };
};

/**
 * Get the invoiced price for a single run of a notice.
 *
 * Note that this does not attempt to reverse any fee-embedding, so
 * when calling this function be sure to understand the newspaper context!
 */
export const getSingleRunPriceFromInvoice = (
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshotExists<EInvoice>,
  runNumber: number
) => {
  const { publicationDates } = notice.data();
  if (!publicationDates || publicationDates.length <= runNumber) {
    throw new Error(
      `Notice ${notice.id} does not have ${runNumber} publication dates!`
    );
  }

  const publicationDate = firestoreTimestampOrDateToDate(
    publicationDates[runNumber]
  );

  const matchingLineItem = getPublicationLineItemForDate(
    invoice,
    publicationDate
  );
  if (!matchingLineItem) {
    return undefined;
  }

  return matchingLineItem.amount;
};

/**
 * Determine the fee percentage and cap for a given rate, considering first
 * if the rate is for governments or not.
 *
 * Note: this method is not suitable for calculating the final convenience fee
 * for a notice as it does not include the column rep fee which may be present
 * as an additional fee on the newspaper.
 */
const rateToConvenienceFee = (rate: AdRate): ConvenienceFee => {
  if (!isNoticeRate(rate)) {
    return {
      convenienceFeePct: rate.enotice_fee_pct ?? ENOTICE_CONVENIENCE_FEE,
      convenienceFeeCap: undefined
    };
  }
  const { isGovernment, enotice_fee_pct, columnRepFeePct } = rate;

  const basePercentage = isGovernment
    ? ENOTICE_CONVENIENCE_FEE_GOVT
    : enotice_fee_pct ?? ENOTICE_CONVENIENCE_FEE;

  // We charge an additional fee if all of the below are true:
  //  - The rate has columnRepFeePct
  //  - The original percentage was > 0
  const additionalPercentage =
    !!columnRepFeePct && basePercentage ? columnRepFeePct : 0;

  const convenienceFeePct = basePercentage + additionalPercentage;

  return {
    convenienceFeePct,
    convenienceFeeCap: rate.convenienceFeeCap
  };
};

/**
 * Get the column rep fee percentage specified in a newspaper's additional fees.
 */
const getColumnRepFeePctFromNewspaper = (
  convenienceFee: ConvenienceFee,
  newspaper: Pick<EOrganization, 'additionalFees'>
): number => {
  const { additionalFees } = newspaper;
  const { convenienceFeePct } = convenienceFee;
  const columnRepFeeOnNewspaper = additionalFees?.find(
    af => af.description === COLUMN_REP_FEE
  );

  // We charge an additional fee if all of the below are true:
  //  - The newspaper has a COLUMN_REP_FEE
  //  - The existing convenience fee percentage is > 0
  //  - A column rep had contact with the notice in a meaningful way
  const columnRepPercentage =
    !!columnRepFeeOnNewspaper &&
    isPercentAdditionalFee(columnRepFeeOnNewspaper) &&
    convenienceFeePct > 0
      ? columnRepFeeOnNewspaper.feePercentage
      : 0;

  return columnRepPercentage;
};

type NoticeProductData = Pick<
  ENotice,
  'fixedPrice' | 'publicationDates' | 'columns' | 'noticeType'
>;

type OrderProductData = Pick<NewspaperOrder, 'colorOptions'> &
  Pick<Ad, 'orderImages'> &
  Pick<ENotice, 'publicationDates'>;

export type PricingParameters = NoticeProductData | OrderProductData;

export const isNoticePricingParameters = (
  pricingParameters: PricingParameters
): pricingParameters is NoticeProductData => 'columns' in pricingParameters;

/**
 * Calculate notice pricing based on the intrinsic notice properties
 * and the line items for each publication.
 */
export const createDBPricingObjectFromDataAndPublicationLineItems = (
  productType: Product,
  pricingParameters: PricingParameters,
  newspaper: EOrganization,
  rate: AdRate,
  publicationLineItems: LineItem[],
  affidavitData: AffidavitPricingData,
  discountConfig: DiscountConfig | undefined,
  options?: {
    // In the case of coupons, we want the coupon to have precedent over the minimum to allow for free orders
    blockMinimums: boolean;
  }
): DBPricingObj => {
  const { publicationDates } = pricingParameters;
  const lastIndex = publicationDates.length - 1;
  const lastPubDatePlusOneMinute = moment(
    publicationDates[lastIndex].toDate()
  ).add(1, 'm');

  // allow for flat additional line items at the newspaper level
  let percentAdditionalFees: PercentAdditionalFee[] = [];
  // allow for additional line items at the newspaper level
  let lineItems = publicationLineItems;

  // Add discount line items
  if (discountConfig) {
    const updatedLineItems = getUpdatedDiscountLineItemsForNotice(
      discountConfig,
      lineItems
    );
    lineItems = updatedLineItems;
  }

  // allow for additional fees for custom affidavits
  // this enables additional fees for Ogden papers that
  // are requesting notice-type-specific pricing
  if (newspaper.customAffidavitFee) {
    const item: LineItem = {
      date: lastPubDatePlusOneMinute.toDate(),
      amount: newspaper.customAffidavitFee,
      description: 'Custom Affidavit Fee',
      type: LineItemType.fee.value
    };

    lineItems = [item].concat(lineItems);
  }

  const affidavitAdditionalFeeLineItems =
    getAffidavitAdditionalFeeLineItemsFromRate(
      rate,
      affidavitData.mail || [],
      lastPubDatePlusOneMinute.toDate()
    );

  lineItems = lineItems.concat(affidavitAdditionalFeeLineItems);

  const additionalFeeLineItems = getNonAffidavitAdditionalFeeLineItemsFromRate(
    rate,
    pricingParameters,
    lastPubDatePlusOneMinute.toDate(),
    lineItems
  );

  lineItems = lineItems.concat(additionalFeeLineItems);

  if (isOrderRate(rate)) {
    if (isNoticePricingParameters(pricingParameters)) {
      throw Error('Cannot price order with notice pricing parameters');
    }

    if (
      rate.colorFees?.flatFee &&
      !pricingParameters.colorOptions?.isGrayscale &&
      pricingParameters.orderImages?.length
    ) {
      lineItems.push({
        date: lastPubDatePlusOneMinute.toDate(),
        amount: rate.colorFees.flatFee,
        description: 'Color Printing Fee',
        type: LineItemType.fee.value
      });
    }
  }

  /**
   * The cross-paper fees should be calculated after all other fees in order
   * to ensure that any percent additional fees are calculated with all other fees
   */
  if (newspaper.additionalFees) {
    const flatAdditionalFees =
      newspaper.additionalFees.filter(isFlatAdditionalFee);

    percentAdditionalFees = newspaper.additionalFees.filter(
      isPercentAdditionalFee
    );

    const percentAdditionalFeesWithoutRepFee = percentAdditionalFees.filter(
      naf => naf.description !== COLUMN_REP_FEE
    );

    if (
      percentAdditionalFees.length !== percentAdditionalFeesWithoutRepFee.length
    ) {
      getErrorReporter().logInfo(
        'Removed Column Rep fee from percentAdditionalFees.'
      );
      percentAdditionalFees = percentAdditionalFeesWithoutRepFee;
    }

    lineItems = lineItems.concat(
      flatAdditionalFees.map(fee => ({
        date: lastPubDatePlusOneMinute.toDate(),
        amount: fee.amount,
        description: fee.description,
        type: LineItemType.fee.value
      }))
    );
  }

  // Percent additional fees should be calculated based on the sum of all previous line items
  let runningSubtotal = calculateSubtotalFromLineItems(lineItems);
  percentAdditionalFees.forEach(additionalFee => {
    const item: LineItem = {
      date: lastPubDatePlusOneMinute.toDate(),
      amount: (additionalFee.feePercentage / 100) * runningSubtotal,
      description: additionalFee.description,
      type: LineItemType.fee.value
    };

    lineItems = lineItems.concat(item);
    runningSubtotal += item.amount;
  });

  // Tax will always be 0% for now for obits & classifieds: https://columnpbc.slack.com/archives/C063V00UK6W/p1712161715255259
  const isPublicNotice = productType === Product.Notice;
  const taxPct = isPublicNotice ? newspaper.taxPct || 0 : 0;

  const totalAcrossRuns = lineItems.reduce(
    (acc, lineItem) => acc + lineItem.amount,
    0
  );

  const rateMinimum = (options?.blockMinimums ? 0 : rate.minimum) ?? 0;
  const subtotal = Math.floor(Math.max(totalAcrossRuns, rateMinimum));

  const lastNonFeeNonDiscountLineItemIndex =
    getLastNonFeeAndNonDiscountLineItemIndex(lineItems);

  // enforce that all line items are cents
  let roundedSubtotal = 0;
  for (const item of lineItems) {
    const centTotal = Math.floor(item.amount);
    item.amount = centTotal;
    roundedSubtotal += centTotal;
  }
  // put the impact of rounding onto the last non-fee line item
  lineItems[lastNonFeeNonDiscountLineItemIndex].amount +=
    subtotal - roundedSubtotal;

  let additionalFeeTotal = 0;
  let changedFinalLineItem = false;
  if (isNoticeRate(rate) && rate.finalLineItemPricing) {
    for (let i = lineItems.length - 1; i >= 0; i -= 1) {
      if (!isPublicationLineItem(lineItems[i])) {
        additionalFeeTotal += lineItems[i].amount;
      } else if (changedFinalLineItem) {
        lineItems[i].amount = 0;
      } else {
        lineItems[i].amount = totalAcrossRuns - additionalFeeTotal;
        changedFinalLineItem = true;
      }
    }
  }

  const taxAmt = calculateTaxFromLineItems(lineItems, taxPct);

  const convenienceFeeFromRate = rateToConvenienceFee(rate);
  const columnRepFeePctFromNewspaper = getColumnRepFeePctFromNewspaper(
    convenienceFeeFromRate,
    newspaper
  );

  const combinedConvenienceFeePct =
    convenienceFeeFromRate.convenienceFeePct + columnRepFeePctFromNewspaper;

  if (combinedConvenienceFeePct > convenienceFeeFromRate.convenienceFeePct) {
    getErrorReporter().logInfo(
      'Column rep fee from newspaper added to convenience fee',
      {
        newspaperName: newspaper.name,
        oldConvenienceFeePct: `${convenienceFeeFromRate.convenienceFeePct}`,
        newConvenienceFeePct: `${combinedConvenienceFeePct}`
      }
    );
  }

  const convenienceFee = {
    // NOTE: this value for convenienceFee.convenienceFeePct, which is returned in
    // this createDBPricingObjectFromDataAndPublicationLineItems function,
    // is not further adjusted for the actual convenienceFeeInCents value output by calculateConvenienceFee below
    // (viz., as the value may be adjusted by a convenience fee cap or any feeSplits).
    convenienceFeePct: combinedConvenienceFeePct,
    convenienceFeeCap: convenienceFeeFromRate.convenienceFeeCap
  };

  const { convenienceFeeSplit } = isNoticeRate(rate)
    ? rate
    : { convenienceFeeSplit: undefined };

  // This is where we apply to the convenience fee any fee splits that live on the relevant rate.
  const convenienceFeeInCents = calculateConvenienceFee(
    subtotal,
    convenienceFee,
    convenienceFeeSplit
  );
  // This is the only time we should call this function as the notice data has not yet been set.
  // Otherwise, use `getAffidavitFeeInCentsForNotice`.
  // If the subtotal is zero, we don't need to calculate the affidavit fee and automatically set to zero.

  // Fee splits with respect to the Column affidavit fee (stored on ARS and inherited using the usual hierarchy) will be applied here.

  const defaultAffidavitFeeInCents = getAffidavitFeeInCentsFromSettingsAndMail(
    affidavitData.settings,
    affidavitData.mail ?? []
  );

  const affidavitFeeInCents = getFinalAffidavitFeeInCents({
    subtotalInCents: subtotal,
    defaultAffidavitFeeInCents,
    convenienceFee,
    affidavitSettings: affidavitData.settings,
    requiresAffidavit: affidavitData.requiresAffidavit,
    publicationDates: pricingParameters.publicationDates
  });

  const total = subtotal + taxAmt + convenienceFeeInCents + affidavitFeeInCents;

  return {
    lineItems,
    subtotal,
    taxPct: floatToDBPercent(taxPct),
    taxAmt,
    // note -- below convenienceFeePct value is not adjusted for convenience fee caps or feeSplits
    convenienceFeePct: floatToDBPercent(convenienceFee.convenienceFeePct),
    convenienceFee: convenienceFeeInCents,
    ...(convenienceFeeSplit && { convenienceFeeSplit }),
    ...(affidavitFeeInCents && { affidavitFeeInCents }),
    total
  };
};

export type AdDataForPricing = Pick<
  ENotice,
  'noticeType' | 'publicationDates' | 'columns' | 'fixedPrice'
>;

const getDescriptionForLineItem = (
  date: FirebaseTimestamp,
  newspaper: EOrganization,
  rate: AdRate
): string | undefined => {
  const { dayRate, dayEnum } = findDayRate(rate, date);
  if (dayRate) {
    return `${moment(date.toDate()).format('MM/DD/YYYY')}: Custom notice (${
      Day.by_value(dayEnum)?.label || ''
    } Rate)`;
  }
  if (
    rate.product === Product.Obituary ||
    rate.product === Product.Classified
  ) {
    return `${newspaper.name} ${moment(date.toDate()).format('MM/DD/YYYY')} Ad`;
  }
  return undefined;
};

/**
 * This is the one true pre-invoice notice pricing function!
 * Final pricing can only be determined after invoicing: see calculateInvoicePricing()
 */
export const createDBPricingObjectFromData = (
  productType: Product,
  adDataForPricing: PricingParameters,
  newspaper: EOrganization,
  rate: AdRate,
  displayParams: DisplayParams | undefined,
  affidavitPricingData: AffidavitPricingData,
  discountConfig: DiscountConfig | undefined
): DBPricingObj => {
  const displayParameters = displayParams ?? DEFAULT_DISPLAY_PARAMS;

  const { publicationDates } = adDataForPricing;
  const publicationLineItems: LineItem[] = publicationDates.map((date, i) => {
    const amount = calculateSingleRunPrice(
      adDataForPricing,
      displayParameters,
      newspaper,
      rate,
      i
    );
    const description = getDescriptionForLineItem(date, newspaper, rate);
    return {
      date: date.toDate(),
      amount,
      ...(description && { description }),
      type: LineItemType.publication.value
    };
  });

  return createDBPricingObjectFromDataAndPublicationLineItems(
    productType,
    adDataForPricing,
    newspaper,
    rate,
    publicationLineItems,
    affidavitPricingData,
    discountConfig
  );
};

/**
 * Price a notice, allowing overrides of the displayParams and the rate. Useful
 * in places like the placement flow where those fields are dynamic.
 */
export const createDBPricingObject = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>,
  displayParams: EDisplayParams | undefined,
  rateOverride: ERef<ERate> | undefined
): Promise<DBPricingObj> => {
  if (!exists(noticeSnap)) {
    throw new Error('Notice not set');
  }

  const displayParameters = displayParams ?? DEFAULT_DISPLAY_PARAMS;

  const notice = noticeSnap.data();

  const newspaperSnap = await getOrThrow(noticeSnap.data().newspaper);
  const newspaper = newspaperSnap.data();

  const rateRef = rateOverride ?? getNoticeRateRef(notice, newspaper);
  const rate = (await getOrThrow(rateRef)).data();

  const affidavitPricingData = await getAffidavitPricingData(ctx, noticeSnap);

  const discountConfig = await getActiveDiscountConfigForNotice(
    ctx,
    noticeSnap.data()
  );

  return createDBPricingObjectFromData(
    Product.Notice,
    notice,
    newspaper,
    rate,
    displayParameters,
    affidavitPricingData,
    discountConfig
  );
};

/**
 * Price a notice using all its internal data (display params, rate, etc)
 * See: createDBPricingObject
 */
export const createDBPricingFromNotice = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const { displayParams } = noticeSnap.data();
  return createDBPricingObject(
    ctx,
    noticeSnap,
    displayParams,
    /* rateOverride= */ undefined
  );
};

/**
 * Sum the convenience fee and the affidavit fee.
 */
export const getCombinedColumnFeeInCentsFromDbPricingObj = (
  dbPricingObj: DBPricingObj
): number => {
  return (
    (dbPricingObj.convenienceFee ?? 0) + (dbPricingObj.affidavitFeeInCents ?? 0)
  );
};

/**
 * Checks if one of the distribute settings is turned on, other wise it returns false
 */
export const shouldDistributeFee = (
  distributeFeeSettings: DistributeSettings | null | undefined
) => {
  return (
    !!distributeFeeSettings &&
    Object.values(distributeFeeSettings).some(setting => setting)
  );
};

const distributeDbPrice = (
  dbPricingObj: DBPricingObj,
  distributeEnoticeFee: DistributeSettings | undefined,
  rate: Pick<ERate, 'finalLineItemPricing' | 'rateType'> | undefined
): DBPricingObj | DistributedDBPricingObj => {
  const shouldDistributeConvenienceFee =
    !!distributeEnoticeFee?.evenly || !!distributeEnoticeFee?.finalLineItem;

  const affidavitFeeIndex = dbPricingObj.lineItems.findIndex(
    ({ description = '' }) => fuzzyStringContains(description, 'affidavit')
  );
  const shouldDistributeAffidaviteFee =
    // Distribution settings are set to distribute the affidavit fee
    !!distributeEnoticeFee?.affidavitFee &&
    // The pricing object has a column affidavit fee to embed
    !!dbPricingObj.affidavitFeeInCents &&
    // There is a publisher fee line item into which we can embed the affidavit fee
    affidavitFeeIndex !== -1;

  const combinedFee = getCombinedColumnFeeInCentsFromDbPricingObj(dbPricingObj);
  const feeToDistribute = shouldDistributeAffidaviteFee
    ? dbPricingObj.convenienceFee ?? 0
    : combinedFee;

  const taxAmt = calculateTaxFromLineItems(
    dbPricingObj.lineItems,
    dbPricingObj.taxPct
  );

  const { total } = dbPricingObj;
  let { subtotal } = dbPricingObj;
  let distributedFee = 0;

  // If we're distributing the convenience fee, then it should be included in the subtotal
  if (shouldDistributeConvenienceFee) {
    subtotal += dbPricingObj.convenienceFee ?? 0;
    distributedFee += dbPricingObj.convenienceFee ?? 0;
  }

  // If we're distributing the affidavit fee (separately or as part of the convenience fee),
  // then it should be included in the subtotal
  if (shouldDistributeAffidaviteFee || shouldDistributeConvenienceFee) {
    subtotal += dbPricingObj.affidavitFeeInCents ?? 0;
    distributedFee += dbPricingObj.affidavitFeeInCents ?? 0;
  }

  const baseLineItems =
    shouldDistributeConvenienceFee && rate
      ? getDistributedLineItems(
          dbPricingObj.lineItems,
          distributeEnoticeFee,
          rate,
          {
            feeToDistribute,
            expectedSubtotal: subtotal
          }
        )
      : dbPricingObj.lineItems;

  const lineItems = baseLineItems.map((item, idx) => {
    if (!shouldDistributeAffidaviteFee || idx !== affidavitFeeIndex) {
      return item;
    }

    const { amount: publisherAffidavitFee, unitPricing } = item;
    const columnAffidavitFee = dbPricingObj.affidavitFeeInCents ?? 0;

    const amount = publisherAffidavitFee + columnAffidavitFee;

    if (!unitPricing) {
      return { ...item, amount, unitPricing: { price: amount, quantity: 1 } };
    }

    const { quantity } = unitPricing;

    const newUnitPricing = {
      price: amount / quantity,
      quantity
    };

    return { ...item, amount, unitPricing: newUnitPricing };
  });

  return {
    ...dbPricingObj,
    lineItems,
    affidavitFeeInCents: 0,
    convenienceFee: shouldDistributeConvenienceFee
      ? null
      : dbPricingObj.convenienceFee,
    convenienceFeePct: shouldDistributeConvenienceFee
      ? null
      : dbPricingObj.convenienceFeePct,
    subtotal,
    taxAmt,
    total,
    distributed: true,
    distributedFee
  };
};

export const maybeDistributeDbPrice = (
  dbPricingObj: DBPricingObj,
  distributeEnoticeFee: DistributeSettings | undefined,
  rate: Pick<ERate, 'finalLineItemPricing' | 'rateType'> | undefined
): DBPricingObj | DistributedDBPricingObj => {
  if (!shouldDistributeFee(distributeEnoticeFee) || dbPricingObj.distributed) {
    return dbPricingObj;
  }

  return distributeDbPrice(dbPricingObj, distributeEnoticeFee, rate);
};

export const invoiceDataToDBPricingObject = (
  inAppLineItems: LineItem[],
  convenienceFeePct: number,
  convenienceFeeCap: number | undefined,
  optionalAffidavitFeeInCents: number | undefined,
  inAppTaxPct: number,
  distributeEnoticeFee?: DistributeSettings | null,
  rate?: Pick<
    ERate,
    'finalLineItemPricing' | 'rateType' | 'convenienceFeeSplit'
  >,
  appliedBalance?: number
): DBPricingObj | DistributedDBPricingObj => {
  const subtotal = inAppLineItems
    .map(item => item.amount)
    .reduce((pre, cur) => pre + cur, 0);

  const { convenienceFeeSplit } = rate || {};
  const convenienceFee = calculateConvenienceFee(
    subtotal,
    {
      convenienceFeePct,
      convenienceFeeCap
    },
    convenienceFeeSplit
  );

  const affidavitFeeInCents = optionalAffidavitFeeInCents || 0;

  const taxAmt = calculateTaxFromLineItems(inAppLineItems, inAppTaxPct);

  const total =
    subtotal +
    taxAmt +
    convenienceFee +
    affidavitFeeInCents -
    (appliedBalance || 0);

  let obj: DBPricingObj = {
    lineItems: inAppLineItems
      .map(item => {
        // TODO(COREDEV-1031): This brings in extra properties from LineItem
        // which are not on the LineItem type!
        return {
          ...item,
          type: getLineItemType(item),
          date: firestoreTimestampOrDateToDate(item.date)
        };
      })
      .sort(
        (a: LineItem, b: LineItem) =>
          (a.date as Date).getTime() - (b.date as Date).getTime()
      ),
    taxPct: inAppTaxPct,
    taxAmt,
    convenienceFeePct,
    convenienceFee,
    affidavitFeeInCents,
    subtotal,
    total
  };

  // Distribute fees (if applicable)
  obj = maybeDistributeDbPrice(obj, distributeEnoticeFee ?? undefined, rate);

  return obj;
};

export const invoiceToDBPricingObject = ({
  invoiceSnap,
  rateSnap,
  newspaperSnap
}: {
  invoiceSnap: ESnapshotExists<EInvoice>;
  rateSnap: ESnapshot<ERate>;
  newspaperSnap: ESnapshotExists<EOrganization>;
}): DBPricingObj => {
  const {
    inAppLineItems,
    convenienceFeePct,
    convenienceFeeCap,
    pricing: { affidavitFeeInCents },
    inAppTaxPct
  } = invoiceSnap.data();

  return invoiceDataToDBPricingObject(
    inAppLineItems,
    convenienceFeePct,
    convenienceFeeCap,
    affidavitFeeInCents,
    inAppTaxPct,
    getDistributeFeeSettings(newspaperSnap, rateSnap),
    rateSnap.data()
  );
};

export type UILineItem = {
  date: Date | FirebaseTimestamp;
  description: string | null;
  amount: string;
};

export const maybeGetColumnRepFeePctFromNewspaper = (
  newspaper: ESnapshotExists<EOrganization>
) => {
  const columnRepFee = newspaper
    .data()
    .additionalFees?.find(fee => fee.description === COLUMN_REP_FEE);

  return columnRepFee && isPercentAdditionalFee(columnRepFee)
    ? columnRepFee.feePercentage
    : undefined;
};

export const maybeGetColumnRepFeePctFromNotice = async (
  notice: ESnapshotExists<ENotice>,
  rate: ESnapshotExists<ERate>
) => {
  const ratePct = rate.data().columnRepFeePct;
  if (typeof ratePct === 'number') {
    return ratePct;
  }

  const newspaper = await getOrThrow(notice.data().newspaper);
  return maybeGetColumnRepFeePctFromNewspaper(newspaper);
};

/**
 * Calculate the advertiser and publisher Column Reps fees for a notice.
 * Rep fee can be on the rate or newspaper. Rep fees on rates override rep fee on the newspaper
 */
export const calculateColumnRepsFee = async (
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshotExists<EInvoice>
): Promise<{ advertiserFeeInCents: number; publisherFeeInCents: number }> => {
  const noFees = {
    advertiserFeeInCents: 0,
    publisherFeeInCents: 0
  };

  const rate = await getOrThrow(notice.data().rate);
  const columnRepFeePctFromNotice = await maybeGetColumnRepFeePctFromNotice(
    notice,
    rate
  );
  const percentage = columnRepFeePctFromNotice;

  if (!(percentage && percentage > 0)) {
    return noFees;
  }

  const { subtotalInCents, convenienceFeeInCents } =
    getInvoiceAmountsBreakdown(invoice);

  // If the advertiser paid any convenience fee, it should have included the Column Rep fee
  if (convenienceFeeInCents > 0) {
    return {
      advertiserFeeInCents: convenienceFeeInCents,
      publisherFeeInCents: 0
    };
  }

  // Otherwise, the publisher has to pay the column rep fee
  const repFeeInCents = calculateConvenienceFee(
    subtotalInCents,
    {
      convenienceFeePct: percentage,
      convenienceFeeCap: undefined
    },
    undefined // no feeSplit on rep fee
  );

  return {
    publisherFeeInCents: repFeeInCents,
    advertiserFeeInCents: 0
  };
};

/**
 * Calculate the fee that should be paid by the publisher based on async design work.
 */
export const calculateAsyncDesignFee = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshotExists<EInvoice>
): Promise<number> => {
  const config = await getInheritedProperty(
    notice.data().newspaper,
    'asyncDesignPricing'
  );

  if (!config) {
    return 0;
  }

  // Check the events log for the notice. If the notice has any matching events
  // it is eligible for a fee. If there are multiple matches we look for the
  // latest one.
  const events = await ctx
    .eventsRef<NoticeAsyncDesignFinalizedEvent>()
    .where('type', '==', NOTICE_ASYNC_DESIGN_FINALIZED)
    .where('notice', '==', notice.ref)
    .orderBy('createdAt', 'desc')
    .limit(1)
    .get();

  if (events.docs.length === 0) {
    return 0;
  }

  if (config.type === 'percent') {
    const { subtotalInCents } = getInvoiceAmountsBreakdown(invoice);
    const feeInCents = Math.round((subtotalInCents * config.subtotalPct) / 100);

    const pages = events.docs[0].data().data.pages ?? 1;
    const perPageCap = config.cap?.perPage
      ? config.cap.perPage * pages
      : Number.MAX_SAFE_INTEGER;
    const totalCap = config.cap?.total ?? Number.MAX_SAFE_INTEGER;

    return Math.min(feeInCents, perPageCap, totalCap);
  }

  if (config.type === 'sized') {
    const modularSize = await notice.data().modularSize?.get();

    const price = modularSize?.data()?.designFeeInCents || 0;

    return price;
  }

  return 0;
};

/**
 * Calculating the total is tricky, because the data.publisherAmountInCents is meant to reflect what we
 * intend to send to the publisher, and thus includes both the sum of all line items (subtotal)
 * plus any applicable tax (subtotal * tax percent). However, we need to calculate our convenience
 * fee from the original subtotal.
 *
 * (Eventually we should store the actual subtotal and tax amount in separate fields on our
 * invoices, but don't want to expand the scope of this hotfix too much)
 *
 * The steps to calculate the proper total are:
 * - Recalculate the subtotal from all line items.
 * - Apply any discounts to the subtotal.
 * - Calculate the tax amount as a percentage of the subtotal.
 * - Calculate our convenience fee as a percentage of the subtotal.
 * - Sum all of the above (plus any affidavit notarization fee) to get the total
 */
export const calculateInvoicePricing = (
  newspaper: ESnapshotExists<EOrganization>,
  data: {
    affidavitReconciliationSettings:
      | AffidavitReconciliationSettings
      | undefined;
    requiresAffidavit: boolean;
    mail: MailDelivery[];
    convenienceFee: ConvenienceFee;
    inAppLineItems: LineItem[];
    inAppTaxPct?: number;
    noticePublicationDates: ENotice['publicationDates'] | undefined;
    rate: ESnapshotExists<ERate> | undefined;
  }
) => {
  const subtotalInCents = data.inAppLineItems.reduce(
    (acc, lineItem) => acc + lineItem.amount,
    0
  );

  const taxPct = data.inAppTaxPct ?? newspaper.data().taxPct ?? 0;

  const taxInCents = calculateTaxFromLineItems(data.inAppLineItems, taxPct);

  const { convenienceFeeSplit } = data.rate?.data() || {};

  const { convenienceFeeInCents, invoiceTrackedConvenienceFeeSplitResult } =
    calculateConvenienceFeeAndFeeSplit(
      subtotalInCents,
      data.convenienceFee,
      convenienceFeeSplit
    );

  const {
    affidavitFeeInCents: defaultAffidavitFeeInCents,
    invoiceTrackedAffidavitFeeSplitResult
  } = getAffidavitFeeInCentsAndFeeSplitFromSettingsAndMail(
    data.affidavitReconciliationSettings,
    data.mail
  );

  const affidavitFeeInCents = getFinalAffidavitFeeInCents({
    subtotalInCents,
    defaultAffidavitFeeInCents,
    convenienceFee: data.convenienceFee,
    affidavitSettings: data.affidavitReconciliationSettings,
    requiresAffidavit: data.requiresAffidavit,
    publicationDates: data.noticePublicationDates
  });

  const totalInCents =
    subtotalInCents + convenienceFeeInCents + affidavitFeeInCents + taxInCents;

  const publisherFeeSplits = removeUndefinedFields({
    convenienceFeeSplitResult: invoiceTrackedConvenienceFeeSplitResult,
    autoAffidavitFeeSplitResult: invoiceTrackedAffidavitFeeSplitResult
  });

  return {
    totalInCents,
    subtotalInCents,
    convenienceFeeInCents,
    affidavitFeeInCents,
    taxInCents,
    ...(Object.keys(publisherFeeSplits).length > 0 && { publisherFeeSplits })
  };
};

export const getColumnRepFeeFromNewspaper = (
  newspaperSnap: ESnapshotExists<EOrganization>
) => {
  const additionalFees = newspaperSnap.data().additionalFees || [];

  const existingColumnRepFee = additionalFees?.find(
    fee => fee.description === COLUMN_REP_FEE
  );

  return existingColumnRepFee;
};

export const isColumnRepFee = (additionalFee: AdditionalFee) => {
  return additionalFee?.description === COLUMN_REP_FEE;
};

/**
 * Returns a ConvenienceFee with Column Rep fee:
 * - When it is a non-government rate, ex. the convenience fee on a rate > 0.
 * - In the case where there are rep fees on the rate and newspaper only one rep fee should be added.
 * - The rep fee on the rate overrides the rep fee on the newspaper.
 */
export const getConvenienceFeeWithRepFeeFromRateOrNewspaper = (
  newspaper: ESnapshotExists<EOrganization>,
  rate: ESnapshotExists<ERate>
): ConvenienceFee => {
  const convenienceFeeFromRate = rateToConvenienceFee(rate.data());

  // If either of the following is true, the rate controls the convenience fee:
  // 1) The rate has the base "convenienceFeePct" set to 0
  // 2) The rate has the "columnRepFeePct" set to > 0
  if (
    convenienceFeeFromRate.convenienceFeePct === 0 ||
    (convenienceFeeFromRate.convenienceFeePct > 0 &&
      rate.data().columnRepFeePct)
  ) {
    return convenienceFeeFromRate;
  }

  const convenienceFee = { ...convenienceFeeFromRate };
  const columnRepFeeFromNewspaper =
    maybeGetColumnRepFeePctFromNewspaper(newspaper);

  if (columnRepFeeFromNewspaper) {
    convenienceFee.convenienceFeePct += columnRepFeeFromNewspaper;
  }

  return convenienceFee;
};

/**
 * Methods only exported for testing
 */
export const __private = {
  distributeDbPrice,
  rateToConvenienceFee,
  getColumnRepFeePctFromNewspaper
};
