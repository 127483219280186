import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useFetchAvailablePublishers } from 'hooks/useFetchAvailablePublishers';
import { Autocomplete } from 'lib/components/Autocomplete';
import { Product } from 'lib/enums';
import { EOrganization, ERef } from 'lib/types';
import { useState, useContext, useEffect } from 'react';
import { PublisherLocationFilter } from 'routes/placeScroll/ConfirmPublisher/PublisherLocationFilter';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrder, NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromSnapshot } from 'lib/model';
import { getOrThrow } from 'lib/utils/refs';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { isAnonymousUserOrder, isPublisherOrder } from 'lib/types/order';
import { Alert } from 'lib/components/Alert';

import { OrderModel } from 'lib/model/objects/orderModel';
import { logAndCaptureException } from 'utils';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { getPublisherOrgOrderTemplate } from 'lib/utils/templates';
import { ColumnService } from 'lib/services/directory';
import { useFetchSubdomainAssociatedPapers } from 'hooks/useFetchSubdomainAssociatedPapers';
import LoadingState from 'components/LoadingState';
import { Ad, CategoryChoiceOption } from 'lib/types/ad';
import { groupBy } from 'lodash';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { asyncFilter, asyncMap, isDefined } from 'lib/helpers';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { getContextKey } from 'sagas/AuthSaga';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ProductPublishingSetting } from 'lib/types/publishingSetting';
import {
  isTemplateValidationIssue,
  validateNewspapersAndFilingTypes
} from './validation';
import MultiStepHeader from '../../components/MultiStepHeader';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import { shouldExcludeNewspaperOrder } from '../CategoryChoice/helpers';
import PublisherCard from './PublisherCard';
import { shouldForceGrayscale } from '../colorHelpers';

const ALL_MEDIUMS = Object.values(PublishingMedium);

const getValidProductPublishingSettings = async (
  newspaper: ERef<EOrganization>,
  product: Product,
  isUserPublisher: boolean
): Promise<ResponseOrError<ProductPublishingSetting[]>> => {
  const productPublishingSettingService = new ProductPublishingSettingsService(
    getFirebaseContext()
  );

  const mediums = await asyncFilter(ALL_MEDIUMS, async medium => {
    const result =
      await productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(
        newspaper,
        product,
        medium,
        {
          shouldCreate: false
        }
      );

    // It's fine if the settings aren't there; we only care about other errors.
    if (result.error && !(result.error instanceof NotFoundError)) {
      return result;
    }

    const detailedProductPublishingSetting = result.response;

    if (
      !detailedProductPublishingSetting ||
      detailedProductPublishingSetting.filingTypes.every(
        filingType => !filingType.isVisibleToUser(isUserPublisher)
      ) ||
      detailedProductPublishingSetting.publishingSetting.modelData.deadlines.every(
        deadline => !deadline.publish
      )
    ) {
      return wrapSuccess(null);
    }

    return wrapSuccess(
      result.response?.productPublishingSetting.modelData || null
    );
  });

  return mediums;
};

const getFilingTypeForProduct = async (
  organizationModel: OrganizationModel,
  selectedFilingType: CategoryChoiceOption,
  anonymousOrder: boolean,
  product: Product
) => {
  const validFilingTypesResult = await asyncFilter(
    ALL_MEDIUMS,
    async medium => {
      const { response: matchedFilingType, error: filingTypeMatchError } =
        await organizationModel.isFilingTypeAvailableForNewspaper({
          selectedFilingType,
          product,
          publishingMedium: medium,
          anonymousOrder
        });

      const result =
        !matchedFilingType ||
        shouldExcludeNewspaperOrder({
          matchedFilingType,
          filingTypeMatchError,
          anonymousOrder
        })
          ? null
          : [medium, matchedFilingType];

      return wrapSuccess(result);
    }
  );

  if (validFilingTypesResult.error) {
    return validFilingTypesResult;
  }

  const validFilingTypes: Record<PublishingMedium, FilingTypeModel> =
    Object.fromEntries(validFilingTypesResult.response);

  return wrapSuccess(validFilingTypes);
};

async function getNewspaperOrder(
  product: Product,
  newspaper: OrganizationModel,
  publishingMedium: PublishingMedium,
  anonymousOrder: boolean,
  inputData: Partial<Ad>
) {
  const { response: adTemplate, error } = await getPublisherOrgOrderTemplate(
    getFirebaseContext(),
    newspaper.ref,
    product,
    publishingMedium
  );

  if (error) {
    logAndCaptureException(
      ColumnService.OBITS,
      error,
      'Unable to get the template for order',
      { newspaperId: newspaper.id, product }
    );
    return wrapError(error);
  }

  const selectedFilingType = inputData.filingTypeName as CategoryChoiceOption;
  const validFilingTypesResult = await getFilingTypeForProduct(
    newspaper,
    selectedFilingType,
    anonymousOrder,
    product
  );

  if (validFilingTypesResult.error) {
    return validFilingTypesResult;
  }

  const productFilingType = validFilingTypesResult.response[publishingMedium];

  const grayscaleResult = await shouldForceGrayscale(productFilingType);

  if (grayscaleResult.error) {
    return grayscaleResult;
  }

  return wrapSuccess({
    newspaper: newspaper.ref,
    adTemplate: productFilingType?.modelData.template || adTemplate,
    publishingDates: [],
    status: NewspaperOrderStatus.DRAFT,
    publishingMedium,
    colorOptions: {
      isGrayscale: grayscaleResult.response,
      backgroundColor: 'transparent'
    },
    // check if productFilingType exists before adding it to the order so we don't have an undefined field in firestore
    ...(productFilingType
      ? {
          filingType: productFilingType.ref,
          layout: productFilingType.getSupportedLayouts(inputData)[0]
        }
      : {})
  } as Partial<NewspaperOrder>);
}

const getShouldShowPublications = ({
  relatedPublisherOptions,
  isPublisher,
  subdomain
}: {
  relatedPublisherOptions: { label: string; value: string }[];
  isPublisher: boolean;
  subdomain: string | null;
}) => {
  // for publishers
  if (isPublisher) {
    // hide if there are no options besides the current
    return {
      showAddPublications: relatedPublisherOptions.length > 1,
      useRelatedPublishers: true
    };
  }

  // for advertisers

  // hide if you are on a subdomain and there are no other options
  return {
    showAddPublications: !subdomain || relatedPublisherOptions.length > 1,
    useRelatedPublishers: false
  };
};

const getTitleAndDescriptionForAddPublisherSetup = ({
  showAddPublications,
  productTypeName
}: {
  showAddPublications: boolean;
  productTypeName: string;
}) => {
  if (showAddPublications) {
    return {
      title: `Where would you like to publish your ${productTypeName}?`,
      description: "Choose the newspapers that you'd like to publish with."
    };
  }
  return {
    title: `How would you like to place your order?`,
    description: 'Control how this order is sent to pagination.'
  };
};

export type SelectPublicationProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onNewspaperOrdersFormDataChange: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  inputData: Partial<Ad>;
  orderModel: OrderModel;
  onUpdateAd: (update: Partial<Ad>) => void;
};

function SelectPublication({
  newspaperOrdersFormData,
  onNewspaperOrdersFormDataChange,
  product,
  inputData,
  orderModel
}: SelectPublicationProps) {
  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
  const isPublisher = useAppSelector(selectIsPublisher);

  const context = getFirebaseContext();

  const organizations = context.organizationsRef();

  const newspaperOrdersWithMedium = newspaperOrdersFormData.filter(
    (
      no
    ): no is Partial<NewspaperOrder> &
      Pick<NewspaperOrder, 'publishingMedium'> => isDefined(no.publishingMedium)
  );

  const newspaperOrdersByNewspaperId = groupBy(
    newspaperOrdersWithMedium,
    no => no.newspaper?.id
  ) as Record<string, typeof newspaperOrdersWithMedium>;

  const [searchedNewspaperId, setSearchedNewspaperId] = useState<
    string | undefined
  >(undefined);

  const [addNewspaperAlert, setAddNewspaperAlert] = useState('');
  const anonymousOrder = isAnonymousUserOrder(orderModel.modelData);

  const { publishersAvailableForPlacement } = useContext(NewspapersContext);
  const searchedNewspaperLoaded = publishersAvailableForPlacement.find(
    o => o.id === searchedNewspaperId
  );
  const {
    loading: availablePublishersLoading,
    stateOptions,
    stateFilter,
    setStateFilter,
    publisherOptions
  } = useFetchAvailablePublishers({
    restrictedSingleState: undefined,
    isUserPublisher: false,
    restrictedPublisherIds: [],
    product
  });

  const {
    loading: relatedPublishersLoading,
    relatedPublisherOptions,
    autoSelectedPaper
  } = useFetchSubdomainAssociatedPapers(product, stateFilter);

  const [
    validProductPublishingSettingsByNewspaperId,
    setProductPublishingSettingsByNewspaperId
  ] = useState<Record<string, ProductPublishingSetting[]>>({});

  const { isLoading: isLoadingValidSettings } = useAsyncEffect({
    fetchData: async () => {
      const uniquePublishers = newspaperOrdersFormData.filter(
        (o, idx) =>
          newspaperOrdersFormData.findIndex(
            otherOrder => otherOrder.newspaper?.id === o.newspaper?.id
          ) === idx
      );
      const validSettingsForExistingPapersResult = await asyncMap(
        uniquePublishers,
        async o => {
          if (!o.newspaper) {
            return wrapError(Error('Newspaper not found'));
          }

          const [
            getPublishingSettingsError,
            validProductPublishingSettingsForPaper
          ] = await getValidProductPublishingSettings(
            o.newspaper,
            product,
            isPublisherOrder(orderModel.modelData)
          );

          if (getPublishingSettingsError) {
            return wrapError(getPublishingSettingsError);
          }

          return wrapSuccess([
            o.newspaper.id,
            validProductPublishingSettingsForPaper
          ]);
        }
      );

      if (validSettingsForExistingPapersResult.error) {
        return validSettingsForExistingPapersResult;
      }

      const validMediumsForExistingPapers: Record<
        string,
        ProductPublishingSetting[]
      > = Object.fromEntries(validSettingsForExistingPapersResult.response);

      setProductPublishingSettingsByNewspaperId(validMediumsForExistingPapers);

      return wrapSuccess(validMediumsForExistingPapers);
    },
    dependencies: []
  });

  const loading =
    relatedPublishersLoading ||
    availablePublishersLoading ||
    isLoadingValidSettings;

  async function addNewspaper(newspaperId: string) {
    if (newspaperOrdersByNewspaperId[newspaperId]) {
      return;
    }
    const newspaperRef = organizations.doc(newspaperId);

    const [publishingSettingsError, validPublishingSettings] =
      await getValidProductPublishingSettings(
        newspaperRef,
        product,
        isPublisherOrder(orderModel.modelData)
      );

    if (publishingSettingsError) {
      setAddNewspaperAlert(
        'Failed to get valid publishing mediums for newspaper.'
      );
      return;
    }

    let newspaperPublishingSettings = validPublishingSettings;

    setProductPublishingSettingsByNewspaperId({
      ...validProductPublishingSettingsByNewspaperId,
      [newspaperId]: validPublishingSettings
    });

    const newspaperSnapshot = await getOrThrow(newspaperRef);
    const organizationModel = getModelFromSnapshot(
      OrganizationModel,
      context,
      newspaperSnapshot
    );

    // We need the filing type model out of the block below
    // TODO: refactor this with obit category select unification. We should move this into a helper to set layout and filing type for a newspaper order.
    let availableClassifiedFilingTypesByMedium: Partial<
      Record<PublishingMedium, FilingTypeModel>
    > = {};
    // Check if newspaper supports category (aka filing type) selected. We only need to do this for classifieds
    if (inputData.filingTypeName) {
      const validFilingTypesResult = await getFilingTypeForProduct(
        organizationModel,
        inputData.filingTypeName,
        anonymousOrder,
        product
      );

      if (validFilingTypesResult.error) {
        setAddNewspaperAlert('Failed to validate filing types for newspaper.');
        return;
      }

      availableClassifiedFilingTypesByMedium = validFilingTypesResult.response;

      newspaperPublishingSettings = newspaperPublishingSettings.filter(
        publishingSetting =>
          Object.keys(availableClassifiedFilingTypesByMedium).includes(
            publishingSetting.publishingMedium
          )
      );
      /*
       * Set add error alert if filing type is unavailable for selected newspaper.
       */
      if (!Object.keys(newspaperPublishingSettings).length) {
        setAddNewspaperAlert(
          `${organizationModel.modelData.name} selected does not support ${inputData.filingTypeName}. Please select another publisher.`
        );
        return;
      }

      const validationStatus = await validateNewspapersAndFilingTypes({
        newspaperOrders: [
          ...newspaperOrdersFormData,
          ...Object.keys(availableClassifiedFilingTypesByMedium).map(
            medium => ({
              newspaper: newspaperRef,
              publishingMedium: medium as PublishingMedium
            })
          )
        ],
        selectedFilingTypeLabel: inputData.filingTypeName,
        product,
        anonymousOrder
      });
      if (!validationStatus.isValid) {
        const alertMessage = isTemplateValidationIssue(validationStatus.details)
          ? `The following publications have templates for ${
              inputData.filingTypeName
            } ads that do not support placing in multiple publications: ${validationStatus.details.papersWithTemplatedFilingTypes.join(
              ', '
            )}`
          : 'An unexpected error occurred while validating selected publishers. Please try again.';
        setAddNewspaperAlert(alertMessage);
        return;
      }

      setAddNewspaperAlert('');
    }

    const newOrdersResult = await asyncMap(
      newspaperPublishingSettings,
      publishingSetting =>
        getNewspaperOrder(
          product,
          organizationModel,
          publishingSetting.publishingMedium,
          anonymousOrder,
          inputData
        )
    );

    if (newOrdersResult.error) {
      setAddNewspaperAlert('Failed to add newspaper');
      return;
    }

    onNewspaperOrdersFormDataChange([
      ...newspaperOrdersFormData,
      ...newOrdersResult.response
    ]);
    setSearchedNewspaperId(newspaperId);
  }

  async function removeNewspaper(newspaperId: string) {
    onNewspaperOrdersFormDataChange(
      newspaperOrdersFormData.filter(no => no.newspaper?.id !== newspaperId)
    );
  }

  useEffect(() => {
    if (newspaperOrdersFormData.length || !autoSelectedPaper) {
      return;
    }

    // Auto selected paper is either the publisher's active organization or the paper associated with the custom subdomain
    if (autoSelectedPaper) {
      void addNewspaper(autoSelectedPaper.value);
    }
  }, [autoSelectedPaper?.value]);

  const allFormattedPublisherOptions = publisherOptions.map(o => ({
    label: o.name,
    value: o.id
  }));

  const { showAddPublications, useRelatedPublishers } =
    getShouldShowPublications({
      subdomain: getContextKey(),
      isPublisher,
      relatedPublisherOptions
    });

  const selectedNewspaperIds = Object.keys(newspaperOrdersByNewspaperId);

  const { title, description } = getTitleAndDescriptionForAddPublisherSetup({
    showAddPublications,
    productTypeName
  });

  return (
    <>
      <MultiStepHeader title={title} description={description} />
      {addNewspaperAlert && (
        <Alert
          id="add-newspaper-error"
          onDismiss={() => setAddNewspaperAlert('')}
          title={addNewspaperAlert}
          status="error"
          icon={<ExclamationCircleIcon className="h-5 w-5" />}
        ></Alert>
      )}

      <div className="grid grid-cols-12 gap-6">
        {showAddPublications && (
          <>
            <div className="col-span-12 md:col-span-6 xl:col-span-8">
              <Autocomplete
                id="selectPublisher"
                labelText="Add publications"
                placeholder={`Choose one or more places to run your ${productTypeName}`}
                value={
                  searchedNewspaperLoaded && !loading ? searchedNewspaperId : ''
                }
                options={
                  useRelatedPublishers
                    ? relatedPublisherOptions
                    : allFormattedPublisherOptions
                }
                onChange={newspaperId => {
                  if (
                    newspaperOrdersFormData.find(
                      o => o.newspaper?.id === newspaperId
                    )
                  ) {
                    void removeNewspaper(newspaperId);
                  } else if (newspaperId) {
                    void addNewspaper(newspaperId);
                  }
                }}
                loading={loading}
                required={publishersAvailableForPlacement.length === 0}
                validationMessages={{
                  valueMissing: 'Please select a publisher'
                }}
                selectedOptionsValues={selectedNewspaperIds}
                showCheckBoxForSelectedItems
              />
            </div>
            <div className="col-span-12 md:col-span-6 xl:col-span-4 pt-8">
              <PublisherLocationFilter
                stateOptions={stateOptions}
                onStateChange={state => {
                  setStateFilter(state);
                }}
                activeFilters={{ stateFilter }}
              />
            </div>
          </>
        )}

        <div className="col-span-12 flex flex-col gap-8">
          {loading ? (
            <LoadingState
              isFullScreen={false}
              context={{
                service: ColumnService.OBITS,
                location: 'Ad placement - Select publication',
                tags: {
                  product,
                  availablePublishersLoading: availablePublishersLoading
                    ? 'true'
                    : 'false',
                  relatedPublishersLoading: relatedPublishersLoading
                    ? 'true'
                    : 'false',
                  adPlacementFlow: 'true',
                  orderId: orderModel.id
                }
              }}
            />
          ) : (
            Object.entries(newspaperOrdersByNewspaperId).map(
              ([newspaperId, ordersForNewspaper]) => {
                /**
                 * We are traversing newspaperOrders instead of the newspapers context
                 * so the soft deleting of newspaperOrders can be reflected in the UI
                 */
                const newspaper = publishersAvailableForPlacement.find(
                  n => n.id === newspaperId
                );
                if (!newspaper) return null;
                return (
                  <div key={newspaper.id} className="col-span-12">
                    <PublisherCard
                      inputData={inputData}
                      onRemovePublisher={() => {
                        void removeNewspaper(newspaperId);
                      }}
                      required={
                        newspaper.data().name === autoSelectedPaper?.label
                      }
                      newspaper={newspaper}
                      validProductPublishingSettings={
                        validProductPublishingSettingsByNewspaperId[
                          newspaperId
                        ] || []
                      }
                      publishingMediums={ordersForNewspaper.map(
                        o => o.publishingMedium
                      )}
                      onPublishingMediumsChange={async value => {
                        setAddNewspaperAlert('');

                        const missingMedium = !ordersForNewspaper.some(
                          o => o.publishingMedium === value
                        );

                        if (missingMedium) {
                          const newOrderResult = await getNewspaperOrder(
                            product,
                            getModelFromSnapshot(
                              OrganizationModel,
                              getFirebaseContext(),
                              newspaper
                            ),
                            value,
                            anonymousOrder,
                            inputData
                          );

                          if (newOrderResult.error) {
                            return;
                          }

                          onNewspaperOrdersFormDataChange([
                            ...newspaperOrdersFormData,
                            newOrderResult.response
                          ]);
                        } else {
                          if (
                            !ordersForNewspaper.some(
                              o => o.publishingMedium !== value
                            )
                          ) {
                            setAddNewspaperAlert(
                              'At least one publishing medium must be selected.'
                            );

                            return;
                          }

                          onNewspaperOrdersFormDataChange(
                            newspaperOrdersFormData.filter(
                              o =>
                                o.newspaper?.id !== newspaperId ||
                                o.publishingMedium !== value
                            )
                          );
                        }
                      }}
                    />
                  </div>
                );
              }
            )
          )}
        </div>
      </div>
    </>
  );
}

export default SelectPublication;
