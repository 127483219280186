import React, { useEffect, useRef, useState } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { SearchableOrganizationRecordFilter } from 'lib/types/searchable';
import { OccupationType, OrganizationType } from 'lib/enums';
import { ERequestTypes, EResponseTypes } from 'lib/types';
import { SelectedOrganizationItem } from 'routes/register/types';
import { logAndCaptureException } from 'utils';
import { SearchedOrganizations } from 'lib/types/responses';
import useOutsideClickListener from 'lib/components/hooks/useOutsideClickListener';
import { TextField } from 'lib/components/TextField';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { Badge } from 'lib/components/Badge';
import api from 'api';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableOrganizations } from 'redux/auth';
import { ColumnService } from 'lib/services/directory';
import SearchedOrganizationsList from '../SearchedOrganizationsList';

const getOrganizationsList = async ({
  organizationSearchText,
  occupationType
}: {
  organizationSearchText: string;
  occupationType: number;
}) => {
  const relevantOrganizationType = {
    [OccupationType.government_official.value]:
      OrganizationType.government.value,
    [OccupationType.lawyer.value]: OrganizationType.law_firm.value,
    [OccupationType.other_organization.value]:
      OrganizationType.other_organization.value
  }[occupationType];
  const filters: SearchableOrganizationRecordFilter[] = [
    { type: [relevantOrganizationType] },
    { issearchable: [Number(true)] },
    { isactive: [Number(true)] }
  ];
  const postReqBody: ERequestTypes['search/organizations'] = {
    search: organizationSearchText,
    filters
  };
  const { results, error }: EResponseTypes['search/organizations'] =
    await api.post('search/organizations', postReqBody);
  if (error) {
    logAndCaptureException(
      ColumnService.AUTH_AND_USER_MANAGEMENT,
      new Error('Unable to search advertiser organizations'),
      `Error in searching an advertiser organization while registration. Filters: ${JSON.stringify(
        filters
      )}`
    );
    return null;
  }
  return results;
};

type JoinExistingOrganizationProps = {
  onStartJoiningNewOrganization: () => void;
  onUpdateOrganizationsToJoin: (values: SelectedOrganizationItem[]) => void;
  creatingNewOrganization: boolean;
  organizationsToJoin: SelectedOrganizationItem[];
  occupationType: number;
};

export default function JoinExistingOrganization({
  onStartJoiningNewOrganization,
  onUpdateOrganizationsToJoin,
  creatingNewOrganization,
  organizationsToJoin,
  occupationType
}: JoinExistingOrganizationProps) {
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);

  const [showOrganizationDropdown, setShowOrganizationDropdown] =
    useState(false);
  const [organizationsList, setOrganizationsList] = useState<
    SearchedOrganizations[]
  >([]);
  const [searchingOrganizations, setSearchingOrganizations] = useState(false);
  const [searchOrgText, setSearchOrgText] = useState('');

  const organizationDropdownRef = useRef(null);
  useOutsideClickListener(organizationDropdownRef, () =>
    setShowOrganizationDropdown(false)
  );

  const removeSelectedItem = (item: SelectedOrganizationItem) => {
    const newOrganizationsToJoin = organizationsToJoin.filter(
      organizationToJoin => organizationToJoin.label !== item.label
    );
    onUpdateOrganizationsToJoin(newOrganizationsToJoin);
  };

  useEffect(() => {
    if (creatingNewOrganization) {
      setShowOrganizationDropdown(false);
      setSearchOrgText('');
    }
  }, [creatingNewOrganization]);

  const searchOrg = useDebounce(searchOrgText, 400);
  useEffect(() => {
    const resetOrganizationsInDropdown = async () => {
      setSearchingOrganizations(true);
      setOrganizationsList([]);
      const newSearchedOrganizations = await getOrganizationsList({
        organizationSearchText: searchOrg,
        occupationType
      });
      if (newSearchedOrganizations) {
        // filter out organizations that are already available to the user
        const validSearchedOrganizations = newSearchedOrganizations.filter(
          organization => {
            return !availableOrganizations.find(
              availableOrganization =>
                availableOrganization.id === organization.id
            );
          }
        );
        setOrganizationsList(validSearchedOrganizations);
      }
      setSearchingOrganizations(false);
    };
    void resetOrganizationsInDropdown();
  }, [searchOrg]);

  return (
    <div className="w-128 mt-8">
      <TextField
        id="search-org"
        labelText=""
        placeholder="Search to join existing organizations"
        onFocus={() => {
          setShowOrganizationDropdown(true);
          onStartJoiningNewOrganization();
        }}
        value={searchOrgText}
        onChange={newValue => setSearchOrgText(newValue)}
        prefix={
          organizationsToJoin.length && (
            <>
              {organizationsToJoin.map(item => (
                <Badge
                  key={`${item.label}-selected-item`}
                  status="info"
                  endIcon={
                    <XMarkIcon
                      onClick={() => removeSelectedItem(item)}
                      className="h-4 w-4"
                    />
                  }
                >
                  {item.label}
                </Badge>
              ))}
            </>
          )
        }
        suffix={
          <MagnifyingGlassIcon className="w-6 h-6 text-column-gray-400" />
        }
      />
      {showOrganizationDropdown && (
        <div
          className="z-20 absolute sm:w-auto md:w-128 lg:w-128"
          ref={organizationDropdownRef}
        >
          {/* Render a dropdown with searched organizations list */}
          <SearchedOrganizationsList
            organizationsList={organizationsList}
            selectedItems={organizationsToJoin}
            updateSelectedItems={(values: SelectedOrganizationItem[]) => {
              onUpdateOrganizationsToJoin(values);
              setShowOrganizationDropdown(false);
              setSearchOrgText('');
            }}
            onCreateNewOrganization={() => {
              setShowOrganizationDropdown(false);
              setSearchOrgText('');
            }}
            loading={searchingOrganizations}
          />
        </div>
      )}
    </div>
  );
}
