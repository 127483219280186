import { NewspaperOrdersFormData } from 'routes/ads/place/PlacementFlowStepSelector';
import { OrderModel } from 'lib/model/objects/orderModel';
import { Layout } from 'lib/types/layout';
import { Alert } from 'lib/components/Alert';
import { Ad, isObituary } from 'lib/types/ad';
import {
  isAdvertiserWithOrganizationOrder,
  ConsolidatedOrderPricing
} from 'lib/types/order';
import { Obituary } from 'lib/types/obituary';
import { NotFoundError, wrapErrorAsColumnError } from 'lib/errors/ColumnErrors';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import LoadingState from 'components/LoadingState';
import LayoutSelector from './LayoutSelector';
import { TopActionBar } from './TopActionBar';
import FormattedEditorWrapper from './FormattedEditorWrapper';
import ShowLogoSwitch from './ShowLogoSwitch';
import CustomSwitch from './CustomSwitch';
import { useShowColorToggle } from '../colorHelpers';

export type DraftContentProps<T extends Ad> = {
  adData: Partial<T>;
  onAdChange: (ad: Partial<T>) => void;
  order: OrderModel;
  version: number;
  onUpdateNewspaperOrdersFormData: (
    newspaperOrderFormData: NewspaperOrdersFormData
  ) => void;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  consolidatedOrderPricing: ConsolidatedOrderPricing | undefined;
  priceLoading: boolean;
  priceIsStale: boolean;
  canEditContent: boolean;
};

function DraftContent<T extends Ad>({
  adData,
  onAdChange,
  consolidatedOrderPricing,
  order,
  version,
  onUpdateNewspaperOrdersFormData,
  newspaperOrdersFormData,
  priceLoading,
  priceIsStale,
  canEditContent
}: DraftContentProps<T>) {
  // TODO: Pass layout in depending on which newspaperOrder we're on
  const { layout, filingType, colorOptions } = newspaperOrdersFormData[0];
  const advertiserOrder = isAdvertiserWithOrganizationOrder(order.modelData);

  const { value: advertiserLogo } = useAsyncEffect({
    fetchData: async () => {
      if (!advertiserOrder) {
        return '';
      }

      const { response: advertiserOrg, error: getAdvertiserOrgError } =
        await order.getAdvertiserOrganization();

      if (getAdvertiserOrgError) {
        throw wrapErrorAsColumnError(getAdvertiserOrgError, NotFoundError);
      }

      return advertiserOrg?.modelData.icon || '';
    },
    errorConfig: {
      service: ColumnService.OBITS,
      message: 'Failed to retrieve advertiser logo',
      tags: {
        orderId: order?.id
      }
    },
    dependencies: [order.id]
  });

  const { value: showToggle, isLoading: isLoadingGrayscaleDefault } =
    useShowColorToggle({ filingType, dependencies: [] });

  if (isLoadingGrayscaleDefault) {
    return (
      <LoadingState
        isFullScreen={false}
        context={{
          service: ColumnService.ORDER_PLACEMENT,
          tags: { orderId: order.id }
        }}
      />
    );
  }

  if (!layout) {
    return (
      <Alert
        id="missing-layout"
        description="Layout is missing in draft content step. Please refresh the page."
      />
    );
  }
  if (!filingType) {
    return (
      <Alert
        id="missing-filing-type"
        description="Publishing category is missing in draft content step. Please refresh the page."
      />
    );
  }

  const onLayoutChange = (newLayout: Layout) => {
    const newNewspaperOrdersFormData = newspaperOrdersFormData.map(
      newspaperOrder => ({
        ...newspaperOrder,
        layout: newLayout
      })
    );
    onUpdateNewspaperOrdersFormData(newNewspaperOrdersFormData);

    // If the new layout supports fewer photos than are currently on the order, we should remove the extra photos
    // also make sure to remove the crop data from the images as it may now be invalid
    const newAdData = {
      ...adData,
      orderImages: adData.orderImages?.slice(0, newLayout.photos).map(image => {
        const { crop, ...rest } = image;
        return { ...rest };
      })
    };
    onAdChange(newAdData);
  };

  const renderLogoSwitch =
    advertiserOrder && advertiserLogo && isObituary(adData);

  return (
    <>
      <div className="flex flex-col w-full bg-white items-center border-b border-column-gray-100 p-4 gap-2">
        <TopActionBar
          adData={adData}
          consolidatedOrderPricing={consolidatedOrderPricing}
          order={order}
          version={version}
          newspaperOrdersFormData={newspaperOrdersFormData}
          priceLoading={priceLoading}
          priceIsStale={priceIsStale}
        />

        <LayoutSelector
          onLayoutChange={onLayoutChange}
          newspaperOrder={newspaperOrdersFormData[0]}
          layout={layout}
          selectionDisabled={!canEditContent}
          adData={adData}
        />

        <div className="flex flex-col w-full lg:w-1/2 gap-2">
          {renderLogoSwitch && (
            <ShowLogoSwitch
              adData={adData}
              advertiserLogo={advertiserLogo}
              onAdChange={onAdChange as (ad: Partial<Obituary>) => void}
            />
          )}
          {showToggle && (
            <CustomSwitch
              label="Publish in color?"
              description="Publish in color to stand out, or turn off to publish in black and white. Click ‘View Proof’ to preview."
              checked={!colorOptions?.isGrayscale}
              onChange={() => {
                onUpdateNewspaperOrdersFormData(
                  newspaperOrdersFormData.map(o => ({
                    ...o,
                    colorOptions: {
                      isGrayscale: !o.colorOptions?.isGrayscale,
                      backgroundColor:
                        o.colorOptions?.backgroundColor || 'transparent'
                    }
                  }))
                );
              }}
            />
          )}
        </div>
      </div>
      <div className="flex flex-wrap justify-center p-2">
        <div className="flex justify-center w-full h-12">
          <div id="custom-toolbar-container" />
        </div>
        <FormattedEditorWrapper
          newspaperOrdersFormData={newspaperOrdersFormData}
          onAdChange={onAdChange}
          adData={adData}
          layout={layout}
          disableEditing={!canEditContent}
          product={order.modelData.product}
          filingType={filingType}
        />
      </div>
    </>
  );
}

export default DraftContent;
