import React, { useState } from 'react';
import { useValidationChecks } from 'lib/components/Checkbox/hooks/useValidationChecks';
import {
  getAccessibilityLinks,
  InputAccessories,
  InputAccessoriesInterface
} from '../InputAccessories';
import CheckboxInput, {
  CheckboxInputProps,
  CheckboxInputType
} from './CheckboxInput';
import WithTooltip from './WithTooltip';

export type CheckboxGroupOption<T extends string = string> = Pick<
  CheckboxInputProps,
  'checked' | 'disabled' | 'labelText' | 'labelDescription' | 'prefix'
> & { tooltipText?: string; id: T };

export type CheckboxGroupProps<T extends string = string> = {
  id: string;
  labelText: React.ReactNode;
  value: CheckboxGroupOption<T>[];
  type?: CheckboxInputType;
  onChange: (
    value: CheckboxGroupOption<T>[],
    changedOption: CheckboxGroupOption<T>
  ) => void;
  /** Applies visual styling to indicate a secondary action in an interface */
  secondary?: boolean;
} & InputAccessoriesInterface;

export default function CheckboxGroup<T extends string = string>({
  id,
  labelText,
  value: options,
  onChange,
  disabled,
  required = false,
  errorText,
  noteText,
  type: checkboxType = 'checkbox',
  ...commonProps
}: CheckboxGroupProps<T>) {
  const [showErrors, setShowErrors] = useState(!!errorText);

  const selectedOption = options.find(option => option.checked);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { currentValidationMessage } = useValidationChecks({
    value: selectedOption?.labelText || '',
    required,
    inputRef,
    setShowErrors
  });
  const errorMessage = currentValidationMessage || errorText;
  const accessibilityLinks = getAccessibilityLinks({
    id,
    errorMessage
  });

  return (
    <InputAccessories
      id={id}
      labelText={labelText}
      disabled={disabled}
      required={required}
      errorText={showErrors ? errorMessage : ''}
      noteText={noteText}
      {...accessibilityLinks}
    >
      {/* This is a hidden input to allow the form to validate in the browser. */}
      <input
        type="hidden"
        name={id}
        value={selectedOption?.labelText}
        ref={inputRef}
        {...accessibilityLinks}
      />
      {/* TODO: Use a legend instead of labelText in the input accessories */}
      <fieldset className="grid grid-cols-2 gap-4">
        {options.map((option, idx) => {
          const { tooltipText, id: checkboxId, ...checkBoxProps } = option;
          return (
            <WithTooltip key={checkboxId} tooltipText={tooltipText}>
              <CheckboxInput
                id={checkboxId}
                {...checkBoxProps}
                {...commonProps}
                type={checkboxType}
                onChange={(checked: boolean) => {
                  const newOption = { ...option, checked };
                  const newValues = options.map((o, oIdx) =>
                    oIdx === idx ? newOption : o
                  );
                  onChange(newValues, newOption);
                }}
              />
            </WithTooltip>
          );
        })}
      </fieldset>
    </InputAccessories>
  );
}
