import { TableLayout } from 'lib/components/TableLayout';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { ColumnService } from 'lib/services/directory';
import { logAndCaptureException } from 'utils';
import {
  DeadlineSettings,
  getDeadlineOverrideKeyFromDate
} from 'lib/types/deadlines';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { Product } from 'lib/enums';
import { ScheduleChangesFilterDialog } from './FilterDialog';
import { ScheduleChangeForm } from './Form';
import { ScheduleChangesRow } from './TableRow';
import {
  DEFAULT_SCHEDULE_CHANGES_FILTER,
  ScheduleChangesFormItem,
  ScheduleChangesTableFilters,
  ScheduleChangesTableItem,
  getFilterHasChanges,
  getNumScheduleChangesFilters,
  getScheduleChangeSettingsForNewPublicationDate,
  publisherDeadlineOverridesToScheduleChangesTableData,
  shouldShowScheduleChangesTableItem
} from './helpers';

type ScheduleChangesProps = {
  productLine: Product;
  activeOrganization: ESnapshotExists<EOrganization>;
  onChange: (newOverrides: Record<string, DeadlineSettings>) => Promise<void>;
  deadlineOverrides: Record<string, DeadlineSettings> | undefined;
};

export function ScheduleChanges({
  productLine,
  activeOrganization,
  onChange,
  deadlineOverrides
}: ScheduleChangesProps) {
  const dispatch = useAppDispatch();
  const [editedScheduleChange, setEditedScheduleChange] =
    useState<ScheduleChangesFormItem>();

  const [filter, setFilter] = useState<ScheduleChangesTableFilters>(
    DEFAULT_SCHEDULE_CHANGES_FILTER
  );
  const [updatedFilter, setUpdatedFilter] = useState(filter);
  const filterHasChanges = getFilterHasChanges(filter, updatedFilter);

  if (editedScheduleChange) {
    return (
      <ScheduleChangeForm
        productLine={productLine}
        activeOrganization={activeOrganization}
        scheduleChange={editedScheduleChange}
        onClose={() => {
          setEditedScheduleChange(undefined);
        }}
        onSubmit={async (
          dayLabel: string,
          updatedScheduleChange: ScheduleChangesTableItem,
          updatedDeadlineSettings: DeadlineSettings
        ) => {
          const { publicationDate } = updatedScheduleChange;

          const newKey = getDeadlineOverrideKeyFromDate(publicationDate);

          const newOverrides = { ...deadlineOverrides };

          newOverrides[newKey] = updatedDeadlineSettings;

          try {
            await onChange(newOverrides);
            setEditedScheduleChange(undefined);
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Schedule Change Saved',
                bodyText: `Your schedule change for ${dayLabel} has been saved successfully.`
              })
            );
          } catch (error) {
            logAndCaptureException(
              ColumnService.SETTINGS_MANAGEMENT,
              error,
              'Failed to save schedule change',
              {
                productLine
              }
            );
            dispatch(
              ToastActions.toastError({
                headerText: 'Error',
                bodyText: `Failed to save schedule change for ${dayLabel}. Please try again.`
              })
            );
          }
        }}
      />
    );
  }

  return (
    <TableLayout<ScheduleChangesTableItem>
      filterable={{
        shouldShowTableItem: (item, search) =>
          shouldShowScheduleChangesTableItem(item, search, filter),
        searchEnabled: false,
        additionalFilters: {
          applyFilterChanges: () => setFilter(updatedFilter),
          filterHasChanges,
          numFiltersActive: getNumScheduleChangesFilters(filter),
          resetFilters: () => {
            setUpdatedFilter(DEFAULT_SCHEDULE_CHANGES_FILTER);
            setFilter(DEFAULT_SCHEDULE_CHANGES_FILTER);
          },
          renderDialog: () => (
            <ScheduleChangesFilterDialog
              onUpdate={setUpdatedFilter}
              value={updatedFilter}
            />
          )
        }
      }}
      header={{
        title: 'Schedule Changes',
        subtitle:
          'Apply one-time schedule changes for specific publication dates.'
      }}
      renderRow={deadlineSetting => (
        <ScheduleChangesRow
          activeOrganization={activeOrganization}
          deadlineSetting={deadlineSetting}
        />
      )}
      archivable={{
        renderWarning: scheduleChange => ({
          header: `Delete the schedule change for ${moment(
            scheduleChange.publicationDate
          ).format('dddd, M/D/YY')}`,
          body: 'Once deleted, the regular deadline for this publication date will apply.',
          buttonText: 'Delete'
        }),
        onArchive: async ({ publicationDate }) => {
          const newOverrides = { ...deadlineOverrides };

          const deleteKey = getDeadlineOverrideKeyFromDate(publicationDate);

          delete newOverrides[deleteKey];

          await onChange(newOverrides);
        },
        enabledArchiveTooltip: 'Delete',
        isArchiveDisabled: false
      }}
      editable={{
        onEdit: scheduleChange => {
          setEditedScheduleChange({ ...scheduleChange, isEdit: true });
        },
        editTooltip: 'Edit'
      }}
      creatable={{
        createButtonText: 'Add Schedule Change',
        onCreate: () => {
          const publicationDate = moment().add({ day: 1 }).toDate();
          setEditedScheduleChange(
            getScheduleChangeSettingsForNewPublicationDate(
              publicationDate,
              activeOrganization
            )
          );
        }
      }}
      data={publisherDeadlineOverridesToScheduleChangesTableData(
        deadlineOverrides
      )}
      columns={[
        'Publication Date',
        'Publishing',
        'Deadline Date',
        'Deadline Time'
      ]}
    />
  );
}
