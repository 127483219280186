import {
  EUser,
  ESnapshotExists,
  ENotice,
  EOrganization,
  EInvoice,
  exists,
  InvoiceCreationInitiatedEvent,
  ESnapshot
} from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import {
  getDisplayName,
  removeUndefinedFields,
  shouldBulkInvoiceUser_v2
} from 'lib/helpers';
import { State, BillingStatusType, InvoiceStatus } from 'lib/enums';
import { getUserStripeId } from 'lib/utils/users';
import { INVOICE_CREATION_INITIATED } from 'lib/types/events';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { EventInvoiceData, LineItem } from 'lib/types/invoices';

const mapEnumToState = (stateEnum: number): string | undefined => {
  const item = State.items().find(item => item.value === stateEnum);
  return item?.label;
};

const getAddressObj = (advertiser: EUser) => {
  if (!advertiser.address) return {};
  return {
    line1: advertiser.address,
    city: advertiser.city,
    state:
      typeof advertiser.state === 'number'
        ? mapEnumToState(advertiser.state)
        : undefined,
    postal_code: advertiser.zipCode
  };
};

const createUserStripeId = async (
  advertiserSnap: ESnapshotExists<EUser>
): Promise<string> => {
  const advertiser = advertiserSnap.data();
  const createCustomer = getCreateCustomerFn();
  const createCustomerObj = {
    name:
      advertiser.organizationName ||
      (advertiser.name
        ? advertiser.name
        : getDisplayName(advertiser.firstName, advertiser.lastName)),
    email: advertiser.email,
    address: getAddressObj(advertiser),
    firestoreUID: advertiserSnap.id
  };

  const result = await createCustomer(createCustomerObj);

  if (!result.data.success) {
    throw new Error(result.data.error.message);
  }

  return result.data.stripeId;
};

/**
 * Returns with the following preference:
 * - The organization's Stripe ID, if the user is a member of it.
 * - The user's Stripe ID.
 * - The Stripe ID of a new customer (which will only be added to the user if it is an individual)
 */
export const getOrCreateUserStripeId = async (
  userSnap: ESnapshotExists<EUser>,
  organizationSnap: ESnapshot<EOrganization> | undefined
): Promise<string> => {
  const stripeId = await getUserStripeId(userSnap, organizationSnap);
  if (!stripeId) {
    return await createUserStripeId(userSnap);
  }

  return stripeId;
};

const getStatusEnum = (
  userNoticeSnap: ESnapshotExists<ENotice>,
  invoiceSnap: ESnapshot<EInvoice> | undefined,
  isPublisher: boolean
) => {
  const { transfer } = userNoticeSnap.data();
  if (!exists(invoiceSnap)) {
    return BillingStatusType.invoice_not_submitted;
  }

  const isInvoicedOutsideColumn = invoiceSnap.data().invoiceOutsideColumn;

  if (invoiceSnap && !transfer) {
    if (isInvoicedOutsideColumn)
      return BillingStatusType.invoiced_outside_column;
    if (
      [InvoiceStatus.unpaid.value, InvoiceStatus.unpayable.value].includes(
        invoiceSnap.data().status
      )
    )
      return BillingStatusType.invoice_submitted_to_advertiser;
    if (
      [
        InvoiceStatus.paid.value,
        InvoiceStatus.partially_refunded.value
      ].includes(invoiceSnap.data().status)
    )
      return BillingStatusType.invoice_paid_by_advertiser;
    if (invoiceSnap.data().status === InvoiceStatus.initiated.value)
      return BillingStatusType.payment_initiated;
    if (invoiceSnap.data().status === InvoiceStatus.payment_failed.value)
      return BillingStatusType.payment_failed;
  }

  if (invoiceSnap.data().status === InvoiceStatus.refunded.value)
    return BillingStatusType.payment_refunded;

  if (!isPublisher && transfer) {
    if (isInvoicedOutsideColumn)
      return BillingStatusType.invoiced_outside_column;
    return BillingStatusType.invoice_paid_by_advertiser;
  }

  if (isPublisher && transfer) {
    if (isInvoicedOutsideColumn)
      return BillingStatusType.invoiced_outside_column;
    return BillingStatusType.transfer_created;
  }
  return BillingStatusType.invoice_not_submitted;
};

const getBillingData = async ({
  userNoticeSnap,
  isPublisher
}: {
  userNoticeSnap: ESnapshotExists<ENotice>;
  isPublisher: boolean;
}) => {
  const { invoice } = userNoticeSnap.data();
  const invoiceSnap = await invoice?.get();
  const statusEnum = getStatusEnum(userNoticeSnap, invoiceSnap, isPublisher);
  return {
    statusEnum,
    invoiceNumber: invoiceSnap?.data()?.invoice_number
  };
};

export const getOrCreateAdvertiserStripeId = async (
  noticeSnap: ESnapshotExists<ENotice>,
  advertiserSnap: ESnapshotExists<EUser>
) => {
  // We prefer the stripe ID on the notice filedBy organization but fall
  // back onto the user.
  const filedByOrganization = await noticeSnap.data().filedBy?.get();
  let stripeId = filedByOrganization?.data()?.stripeId;
  if (!stripeId) {
    stripeId = await getOrCreateUserStripeId(
      advertiserSnap,
      filedByOrganization
    );
  }
  return stripeId;
};

type InvoiceAdvertiserProps = {
  publisherAmountInCents: number;
  lineItems: LineItem[];
  inAppTaxPct: number;
  noticeSnap: ESnapshotExists<ENotice>;
  advertiserSnap: ESnapshotExists<EUser>;
  newspaperSnap: ESnapshotExists<EOrganization>;
  customId?: string;
  customMemo?: string;
  dueDate?: number;
  requireUpfrontPayment?: boolean;
  invoiceOutsideColumn?: boolean;
  user: ESnapshotExists<EUser> | undefined;
};

const invoiceAdvertiser = async ({
  publisherAmountInCents,
  lineItems,
  inAppTaxPct,
  noticeSnap,
  advertiserSnap,
  newspaperSnap,
  customId,
  customMemo,
  dueDate,
  requireUpfrontPayment,
  invoiceOutsideColumn,
  user
}: InvoiceAdvertiserProps) => {
  const stripeId = await getOrCreateAdvertiserStripeId(
    noticeSnap,
    advertiserSnap
  );

  await noticeSnap.ref.update({
    requireUpfrontPayment: !!requireUpfrontPayment
  });

  const isWithinBulkInvoice_v2 = await shouldBulkInvoiceUser_v2(
    getFirebaseContext(),
    noticeSnap,
    newspaperSnap
  );

  const parentOrg = await newspaperSnap.data().parent?.get();
  let bulkInvoiceByPubDate;
  const newspaperBulkInvoicingByPubDate =
    newspaperSnap.data().bulkInvoiceByPubDate;

  // Disablement on the newspaper level overrides parent settings
  if (typeof newspaperBulkInvoicingByPubDate === 'boolean') {
    bulkInvoiceByPubDate = newspaperBulkInvoicingByPubDate;
  } else {
    bulkInvoiceByPubDate = parentOrg?.data()?.bulkInvoiceByPubDate;
  }

  if (isWithinBulkInvoice_v2) {
    await noticeSnap.ref.update({
      bulkInvoiceByPubDate: !!bulkInvoiceByPubDate
    });
  }

  const invoiceData: EventInvoiceData = {
    customer: stripeId,
    lineItems,
    publisherAmountInCents,
    inAppTaxPct,
    userNoticeId: noticeSnap.id,
    organizationId: newspaperSnap.id,
    advertiserId: advertiserSnap.id,
    customId,
    customMemo,
    publisherId: customId || '',
    dueDate,
    invoiceOutsideColumn,
    createdBy: user && user.ref
  };

  removeUndefinedFields(invoiceData);
  await getFirebaseContext().eventsRef<InvoiceCreationInitiatedEvent>().add({
    type: INVOICE_CREATION_INITIATED,
    notice: noticeSnap.ref,
    data: invoiceData,
    createdAt: getFirebaseContext().fieldValue().serverTimestamp()
  });
};

const BillingFunctions = {
  invoiceAdvertiser,
  getBillingData,
  getStatusEnum
};

export default BillingFunctions;
