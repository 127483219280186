import React from 'react';
import { MilestonesClassified } from 'lib/types/classified';
import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';

type MilestonesExtraFieldsProps<T extends MilestonesClassified> = {
  adData: Partial<T>;
  onChangeAd: (ad: Partial<T>) => void;
};

function MilestonesExtraFields<T extends MilestonesClassified>({
  onChangeAd,
  adData
}: MilestonesExtraFieldsProps<T>) {
  return (
    <div className="flex flex-col gap-3">
      <div>
        <h3 className="text-column-gray-500 font-semibold text-sm">
          Additional Information
        </h3>
        <div className="text-column-gray-400 text-sm">
          Tell us more about the big event
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
        <GridInput>
          <ColumnSelect
            id="select-property-type"
            value={adData.milestoneType}
            labelText="What type of event are you commemorating?"
            size="small"
            onChange={milestoneType => onChangeAd({ ...adData, milestoneType })}
            options={[
              {
                value: 'engagement',
                label: 'Engagement'
              },
              {
                value: 'wedding',
                label: 'Wedding'
              },
              {
                value: 'birth',
                label: 'Birth'
              },
              {
                value: 'retirement',
                label: 'Retirement'
              },
              {
                value: 'graduation',
                label: 'Graduation'
              },
              {
                value: 'anniversary',
                label: 'Anniversary'
              },
              {
                value: 'memorial-obituary',
                label: 'Memorial/Obituary'
              },
              {
                value: 'new-job',
                label: 'New Job'
              },
              {
                value: 'promotion',
                label: 'Promotion'
              },
              {
                value: 'moving-relocation',
                label: 'Moving/Relocation'
              },
              {
                value: 'baby-shower',
                label: 'Baby Shower'
              },
              {
                value: 'baptism-christening',
                label: 'Baptism/Christening'
              },
              {
                value: 'adoption',
                label: 'Adoption'
              },
              {
                value: 'bar-bat-mitzvah',
                label: 'Bar/Bat Mitzvah'
              },
              {
                value: 'quinceanera',
                label: 'Quinceañera'
              },
              {
                value: 'first-communion',
                label: 'First Communion'
              },
              {
                value: 'housewarming',
                label: 'Housewarming'
              },
              {
                value: 'military-service-deployment',
                label: 'Military Service/Deployment'
              },
              {
                value: 'other',
                label: 'Other'
              }
            ]}
            allowUndefined
            placeholder="Promotion, housewarming, adoption, and more"
          />
        </GridInput>
        <GridInput>
          <TextField
            id="extra-info-date"
            value={adData.milestoneDate}
            labelText="Milestone Date"
            onChange={milestoneDate => onChangeAd({ ...adData, milestoneDate })}
            size="small"
            maxLength={20}
            required
            placeholder="12/2/2024"
            pattern="\d{2}/\d{2}/\d{4}"
            validationMessages={{
              patternMismatch: 'Please enter in MM/DD/YYYY format'
            }}
          />
        </GridInput>
      </div>
    </div>
  );
}

export default MilestonesExtraFields;
