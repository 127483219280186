import React, { useState } from 'react';
import { connect } from 'react-redux';

import { AuthState } from 'redux/auth';
import { ESnapshotExists, EOrganization, ESnapshot, exists } from 'lib/types';
import { State } from 'lib/enums';
import LoadingState from 'components/LoadingState';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import Archive from './Archive';
import Upload from './Upload';
import { ExternalUpload } from './ExternalUpload';

const UPLOAD_TAB: TabOption = {
  label: 'Upload',
  id: 'upload',
  enabled: true
};

const ARCHIVE_TAB: TabOption = {
  label: 'Archive',
  id: 'archive',
  enabled: true
};

type FTPSettingsType = {
  availableOrganizations: ESnapshotExists<EOrganization>[];
  activeOrganization: ESnapshotExists<EOrganization>;
  organization: ESnapshot<EOrganization> | null;
};

function FTPSettings({
  availableOrganizations,
  activeOrganization,
  organization
}: FTPSettingsType) {
  const [activeTab, setActiveTab] = useState<TabOption>(UPLOAD_TAB);
  if (!exists(organization)) {
    return <LoadingState />;
  }

  const uploadFormat = State.by_value(
    activeOrganization.data().state
  )?.showUploads;

  return (
    <SettingsPage>
      <TabGroup
        onClickTab={setActiveTab}
        activeTab={activeTab}
        tabs={[UPLOAD_TAB, ARCHIVE_TAB]}
        id="statewide-site-uploads-tabs"
      ></TabGroup>
      {activeTab.id === UPLOAD_TAB.id &&
        (!uploadFormat || uploadFormat === 'INTERNAL' ? (
          <Upload
            availableOrganizations={availableOrganizations}
            activeOrganization={activeOrganization}
            organization={organization}
          />
        ) : (
          <ExternalUpload uploadFormat={uploadFormat} />
        ))}
      {activeTab.id === ARCHIVE_TAB.id && (
        <Archive organization={activeOrganization} />
      )}
    </SettingsPage>
  );
}

const mapStateToProps = (state: { auth: AuthState }) => ({
  organization: state.auth.organization
});

export default connect(mapStateToProps)(FTPSettings);
