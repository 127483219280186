import { NotificationType, OccupationType, OrganizationType } from '../enums';
import {
  getOpenInvitesAssociatedWithEmail,
  filterOpenInvitesForAnonUsers
} from '../helpers';
import {
  getAllowedOrganizationSnaps,
  getPendingOrgInvitesForUser
} from '../users';
import {
  EFirebaseContext,
  ESnapshotExists,
  ESnapshot,
  exists,
  EUser,
  EOrganization,
  EUserNotificationType
} from '../types';
import { UserOccupationValue } from '../enums/OccupationType';
import { NotificationTypeItemType } from '../enums/NotificationType';

type EOrganizationUser = EUser & Required<Pick<EUser, 'organization'>>;

/**
 * Check if a user has an organization ref and that they have the occupation
 * of an organization member.
 */
export const isMemberOfOrganization = (
  user: ESnapshot<EUser>,
  orgId: string | undefined
): user is ESnapshotExists<EOrganizationUser> => {
  if (!exists(user)) {
    return false;
  }

  const { allowedOrganizations, occupation } = user.data();

  if (!allowedOrganizations || allowedOrganizations.length === 0) {
    return false;
  }
  const allowedOrgIds = allowedOrganizations.map(org => org.id);
  if (orgId && !allowedOrgIds.includes(orgId)) {
    return false;
  }

  const hasOrgOccupation = [
    OccupationType.government_official.value,
    OccupationType.lawyer.value,
    OccupationType.other_organization.value,
    OccupationType.publishing.value,
    OccupationType.funeral_director.value
  ].includes(occupation);

  return hasOrgOccupation;
};

/**
 * Get the appropriate stripeId for a user given a target organization
 * (e.g. if an invoice has a filedBy org, pass that in)
 */
export const getUserStripeId = async (
  user: ESnapshotExists<EUser>,
  targetUserOrg: ESnapshot<EOrganization> | undefined
): Promise<string | undefined> => {
  if (isMemberOfOrganization(user, targetUserOrg?.id)) {
    const orgStripeId = targetUserOrg?.data()?.stripeId;
    if (orgStripeId) {
      return orgStripeId;
    }
  }

  return user.data().stripeId;
};

/**
 * Determine if a user is a publisher (as opposed to an advertiser).
 */
export const isPublisher = (user: ESnapshotExists<EUser>): boolean => {
  return user.data().occupation === OccupationType.publishing.value;
};

export const getUserNeedsToBeRedirectedToRegistration = async ({
  ctx,
  user,
  pathname
}: {
  pathname: string;
  user: ESnapshotExists<EUser> | null | undefined;
  ctx: EFirebaseContext;
}) => {
  if (!exists(user)) return false;

  const { postRegistrationComplete, anonymous, email } = user.data();

  if (postRegistrationComplete) {
    return false;
  }

  const pendingUserInvites = await getOpenInvitesAssociatedWithEmail(
    ctx,
    (email || '').toLowerCase()
  );
  const pendingIndividualInvites =
    filterOpenInvitesForAnonUsers(pendingUserInvites);
  const onInviteRoute = pathname.includes('/invites/');
  if (pendingIndividualInvites.length && onInviteRoute && anonymous) {
    return true;
  }

  const pendingOrgInvites = await getPendingOrgInvitesForUser(ctx, user);
  if (pendingOrgInvites.length) {
    return false;
  }

  const onRegistrationRequiredRoute =
    pathname === '/register/' ||
    pathname.includes('/notices') ||
    pathname.includes('/place') ||
    pathname === '/';

  return onRegistrationRequiredRoute;
};

/**
 * Determine if a user is a government official.
 */
export const isGovernmentOfficial = (user: ESnapshotExists<EUser>): boolean => {
  return user.data().occupation === OccupationType.government_official.value;
};

/**
 * Determine if a user belongs to a government organization.
 * Adapt this if we need to check parent org, but we're currently assuming
 * a child org will be government as well.
 */
export const belongsToGovernmentOrganization = async (
  user: ESnapshotExists<EUser>
): Promise<boolean> => {
  const organizations = await getAllowedOrganizationSnaps(user);
  for (const organization of organizations) {
    if (
      organization.data().organizationType === OrganizationType.government.value
    ) {
      return true;
    }
  }
  return false;
};

export const createNotificationsObject = (occupation: UserOccupationValue) => {
  const occupation_key = OccupationType.by_value(occupation)?.key;

  if (!occupation_key) {
    throw new Error(`invalid occupation value ${occupation}`);
  }
  const notifications: Record<string, EUserNotificationType> = {};

  NotificationType.items().forEach((notification: NotificationTypeItemType) => {
    const isRelevantToUser = (
      notification.recipient_occupation_type_keys || []
    ).includes(occupation_key || '');

    notifications[notification.key] = {
      inApp: isRelevantToUser,
      email: isRelevantToUser
    };
  });
  return notifications;
};
