import { PublishingMedium } from '../enums/PublishingMedium';
import {
  EOrganization,
  ETransfer,
  ERef,
  ETemplate,
  FirebaseTimestamp
} from '.';
import { LineItem } from './invoices';
import { Layout } from './layout';
import { FilingType, OrderFilingType } from './filingType';
import { DisplayParams } from './notice';

export enum NewspaperOrderStatus {
  /**
   * Order has been archived and status has bubbled into all newspaper orders
   */
  ARCHIVED = 'archived',

  /**
   * Order has been cancelled & refunded.
   * If a transfer has been completed, it will be reversed at end of month.
   * If a transfer has not yet been completed, not reversal will occur.
   */
  CANCELLED = 'cancelled',

  /**
   * Newspaper order has been published
   * A transfer will occur at the end of the month it was completed
   */
  COMPLETE = 'complete',

  /**
   * Soft deleted newspaper order, which allows us to keep transfer and other data
   */
  DELETED = 'deleted',

  /**
   * Order ad is in the process of being created
   */
  DRAFT = 'draft',

  /**
   * Order has been created and is awaiting review
   */
  AWAITING_REVIEW = 'awaiting review',

  /**
   * Order is being actively reviewed by the publisher
   */
  IN_REVIEW = 'in review',

  /**
   * Order has been confirmed by the publisher
   */
  CONFIRMED = 'confirmed'
}

export const PAGINATION_READY_STATUSES = [
  NewspaperOrderStatus.CONFIRMED,
  NewspaperOrderStatus.COMPLETE
];

export type NewspaperOrderPricing = {
  lineItems: LineItem[];
  convenienceFeeInCents: number;
  subtotalInCents: number;

  /**
   * Amount to transfer to the publisher
   */
  publisherAmountInCents: number;

  /**
   * Charging taxes in orders is not currently supported
   */
  taxesInCents: 0;
  totalInCents: number;

  /**
   * Total discount applied to the newspaper order
   */
  totalDiscountInCents?: number;
};

export enum NewspaperOrderLineItemStatus {
  /**
   * Line item is awaiting publication
   */
  PENDING = 'pending',

  /**
   * Line item has been published in the newspaper
   */
  PUBLISHED = 'published',

  /**
   * Line item has been cancelled
   */
  CANCELLED = 'cancelled'
}

export type NewspaperOrderLineItem = {
  amount: number;
  date: string;
  description: string;
  status: NewspaperOrderLineItemStatus;
};

type ColorOptions = {
  isGrayscale: boolean;
  backgroundColor: string;
};

export type NewspaperOrder = {
  /** The edit version of the order to which the newspaperOrder belongs */
  orderVersion: number;
  newspaper: ERef<EOrganization>;
  publishingMedium: PublishingMedium;
  adTemplate?: ERef<ETemplate>;
  filingType: ERef<OrderFilingType>;
  layout: Layout;
  publishingDates: string[];
  pricing?: NewspaperOrderPricing;
  transfer?: ERef<ETransfer>;
  status: NewspaperOrderStatus;
  pdfStoragePath?: string;
  displayParams?: DisplayParams;
  confirmedAt?: FirebaseTimestamp;
  colorOptions?: ColorOptions;

  /**
   * @deprecated
   * This prop is being deleted. Use newspaperOrder.filingType instead!
   */
  oldFilingType: FilingType;
};

export function validateCompleteNewspaperOrder(
  newspaperOrder: Partial<NewspaperOrder>
): newspaperOrder is NewspaperOrder {
  return (
    newspaperOrder !== null &&
    typeof newspaperOrder === 'object' &&
    newspaperOrder.newspaper !== undefined &&
    newspaperOrder.filingType !== undefined &&
    newspaperOrder.layout !== undefined &&
    Array.isArray(newspaperOrder.publishingDates) &&
    newspaperOrder.publishingDates.length > 0 &&
    newspaperOrder.publishingDates.every(date => typeof date === 'string') &&
    newspaperOrder.status !== undefined &&
    Object.values(NewspaperOrderStatus).includes(newspaperOrder.status)
  );
}

export type NewspaperOrderSearchRequest = {
  newspaperIds: string[];

  // YYYY-MM-DD format
  publishingDate?: string;

  statuses?: NewspaperOrderStatus[];
};
